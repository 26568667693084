@import '../vars', '../mixins/mixins';

#color-popup {
  position: relative;
  background-color: transparent;
  overflow: hidden;
  .color {
    height: 300px;
    width: 300px;
    max-height: 100%;
    max-width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: contain;
    @include b(tablet) {
      height: 220px;
      width: 220px; } }
  .color-code {
    font-size: 2.4rem; }
  .color-title,
  .color-code {
    color: white;
    mix-blend-mode: difference; }
  .color-content {} }


.fancybox-bg {
  background: rgba(0, 0, 0, 0.6); }

.fancybox-slide--html .fancybox-close-small {
  padding: 0;
  right: 20px;
  top: 20px;
  width: 45px;
  height: 45px; }


.fancybox-slide--html .fancybox-close-small path {
  fill: #fff; }


/* scrollbar fix jumping */
.compensate-for-scrollbar .header-top,
.compensate-for-scrollbar .back-to-top-btn {
  padding-right: 15px; }


.color-button-wrapper {
  display: flex;
  width: 100%; }

.color-button {
  position: absolute;
  height: calc(100% - 54px);
  bottom: 0;
  width: 50%;
  line-height: 36px;
  text-align: center;
  color: #fff;
  flex-grow: 1;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
  &:focus,&:hover {
    color: #fff; }
  &::before, &::after {
    mix-blend-mode: difference;
    position: absolute;
    top: 35%;
    @include b(xs) {
      top: 35%; } }
  &-prev {
    left: 44px;
    //background: rgba(red, .5)
    &::before {
      left: 0;
      content: '←'; } }
  &-next {
    right: 44px;
    //background: rgba(blue, .5)
    &::after {
      right: 0;
      content: '→'; } } }


