@import '../vars', '../mixins/mixins';

//body, html
//  background: brown !important
//.single-header
//  border: 1px solid #dfdfdf
//  border-left: none
//  border-right: none

.single-blog-post {
  *:not(.coverings_image) {
    img {
      display: block;
      margin: 0 auto 1rem auto;
      max-width: 100%;
      height: auto;
      @include b(sm) {
        &.left {
          float: left;
          margin-right: 1rem; }
        &.right {
          float: right;
          margin-left: 1rem; } } } } }


.single_page,
.single-product .description-text,
.single-addition-content {
  $ffDinProRegular: 'DINPro-Regular';
  $ffDinProMedium: 'DINPro-Medium';
  $ffDinProBold: 'DINPro-Bold';
  h1 {
    margin-bottom: 1.5rem;
    color: #39434E; }

  h2,h3,h4,h5,a:not(.page-numbers):not(.site-button) {
    margin-bottom: 1rem;
    color: #39434E;
    font-family: $ffDinProMedium; }

  h1 {
    font-family: $ffDinProBold; }

  h6 {
    font-family: $ffDinProBold; }

  .focused-link {
    font-family: $ffDinProRegular;
    text-decoration: underline;
    color: #F33A20;
    text-transform: uppercase; }

  section {
    margin-bottom: 3rem; }

  img {
    max-width: 100%;
    height: auto;
    &.aligncenter,
    &.alignleft,
    &.alignright {
      display: block; }
    &.aligncenter {
      margin: 0 auto; }
    &.alignleft {
      margin-right: auto; }
    &.alignright {
      margin-left: auto; } }



  blockquote {
    display: flex;
    align-items: center;
    background: #F5F5F5;
    padding: 2rem;
    margin-bottom: 3rem;
    img {
      margin-right: 3.750rem;
      max-width: 60px; }

    &::before {
      content: '';
      margin-right: 2rem;
      display: block;
      width: 80px;
      height: 80px;
      background: url('../../dist/img/icons/important.svg') center no-repeat;
      background-size: contain; }

    @include b(xs) {
      flex-direction: column;
      &::before {
        margin-left: auto;
        width: 34px;
        height: 34px; } } }


  > p {
    color: #39434E;
    font-size: 1em;
    margin-bottom: 1rem;
    font-family: $ffDinProRegular; }

  > ul:not(.slick-dots),
  > ol:not(.slick-dots) {
    a {
      color: #F33A20;
      text-decoration: underline;
      font-family: $ffDinProMedium;
      &:hover {
        text-decoration: none; } }
    @include b(xs) {
      font-size: 12px; } }

  ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) {
    margin-bottom: 2.500rem;
    padding-left: 0;

    li {
      color: #39434E;
      font-family: $ffDinProRegular;
      font-size: 1em;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      text-align: left;
      & > * {
        display: contents; } }


    li:before {
      content: '';
      display: inline-block;
      background: url('../img/icons/tick.svg') no-repeat;
      background-position: center;
      background-size: contain;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-right: 1rem; } }

  ol:not(.slick-dots) {
    padding: 0;
    list-style-type: none;
    counter-reset: item;
    margin-bottom: 3rem;

    li {
      color: #39434E;
      font-family: $ffDinProRegular;
      font-size: 1em;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      text-align: left;

      &:before {
        content: counter(item) ".";
        counter-increment: item;
        background: none;
        font-size: 1em;
        font-family: $ffDinProBold;
        color: #F33A20;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem; } } } }

.image-holder {
  width: 130px;
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  &.chestno {
    background-image: url('../../dist/img/icons/3.svg'); }
  &.ekonomno {
    background-image: url('../../dist/img/icons/7.svg'); }
  &.vovremya {
    background-image: url('../../dist/img/icons/21.svg'); }
  &.proffessionalno {
    background-image: url('../../dist/img/icons/23.svg'); }
  &.vygodno {
    background-image: url('../../dist/img/icons/22.svg'); }
  &.nadezhno {
    background-image: url('../../dist/img/icons/9.svg'); } }

.iframe-video {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

.wp-video {
  width: 100% !important;
  margin: 0 auto; }

