@import '../vars', '../mixins/mixins';
/*-------------------------------------PRODUCT------------------------------------- */

.product-values {
  margin-bottom: 20px; }

//.product-thumbnail
//  @include b(desktop)
//    margin-top: 3rem

.table-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  .table-row {
    display: flex;
    flex-wrap: wrap;
    @include b(sm) {
      &:nth-child(odd) .table-col {
          background-color: #F5F5F5; } }
    .table-col {
      position: relative;
      width: 25%;
      padding: 20px 7px 20px 30px;
      line-height: 21px;
      font-family: $ffDinProRegular;
      font-size: 15px;
      &:nth-child(odd) {
        width: 28%; }
      &:nth-child(even) {
        width: 22%; }
      &.single {
        width: 100%;
        font-family: $ffDinProMedium; }
      &.filled {
        @include b(xs) {
          background: #F5F5F5; } }
      @include b(tablet) {
        padding: 15px 7px 15px 20px;
        font-size: 14px; }
      @include b(xs) {
        padding: 10px 5px;
        font-size: 13px;
        width: 50%; }
      &:nth-child(even) {
        font-family: $ffDinProMedium;
        text-align: center; }
      &::before {
        @include b(sm) {
          position: absolute;
          top: 0;
          width: 7px;
          height: 100%;
          background: #fff; } }
      @include b(xs) {
        &:nth-child(odd) {
          width: 50%; }
        &:nth-child(even) {
          width: 50%; } }
      &:nth-child(4n-1) {
        &::before {
          @include b(sm) {
            content: '';
            right: 100%; } } }
      &:nth-child(4n-2) {
        &::before {
          @include b(sm) {
            content: '';
            left: 100%; } } } } } }


.product-title,
.product-title-xs,
.single-product h2,
.single-product h3 {
  font-family: $ffDinProMedium;
  font-weight: 600;
  line-height: 1;
  color: #39434E;
  margin: 25px 0 15px 0; }

.product-title {
  font-size: 28px !important; }
.single-product {
  h1 {
    font-size: 32px !important; }
  h2,h3 { // @TODO - Crutch
    font-size: 24px !important; }
  h4 {
    font-size: 20px !important; } }

.product-title-xs {
  font-size: 20px; }

.single-product-wrapper {
  &.object-fit-cover {
    img {
      width: 100%;
      object-fit: cover; } }
  @include b(xs) {
    max-height: 300px; } }

.product-gamma {
  @include b(desktop) {
    padding-left: 20px; }
  &-wrap {
    display: flex; } }


.tab-shortcode {
  .product-gamma-wrap,
  .product-gammas {
    justify-content: center;
    margin: 0 auto;
    max-width: 600px; } }

.product-gamma-wrap,
.product-gammas {
  flex-wrap: wrap;
  @include b(xs) {
    justify-content: center; } }


.product-gamma-wrap-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all .35s;
  &:not(.fancybox-color) {
    @include b(tablet) {
      width: 96px;
      height: 86px; } }
  &.texture {
    &.covering-button {
      width: auto;
      margin-right: 20px; }
    &.single {
      //width: inherit
      //height: inherit
      height: auto;
      &.active {
        p.btn-main,b.btn-main {
          background: $mainColorDark; } }
      &.active-palette,
      &.active {
        a {
          box-shadow: 0 0 0 4px $mainColor; } }
      a {
        box-shadow: 0 0 0 4px #fff; }
      .btn-main {
        padding: 10px;
        font-size: 16px !important; } }
    &:not(.single) {
      border: 4px solid #fff;
      &.active {
        border-color: $mainColor; } }
    p,b {
      line-height: 1;
      margin-top: 0;
      font-size: 12px; } }
  &:not(.texture) {
    .hex {
      border-radius: 50%;
      overflow: hidden; } }
  img {
    margin-bottom: 0 !important;
    width: 76px !important;
    height: 76px !important;
    object-fit: cover;
    @include b(tablet) {
      width: 50px;
      height: 50px; } }
  p {
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
    color: #000000; }
  .hex {
    box-shadow: #797979 2px 2px 7px;
    width: {}
    min-width: 48px;
    min-height: 48px; } }

.single-product {
  margin-top: 1rem;
  .product-gamma-wrap-item {
    margin-bottom: 10px; }
  &:not(.without-linked-image) {
    .product-gamma-wrap-item {
      margin-right: 10px;
      width: 106px;
      height: 106px; } }
  &.without-linked-image {
    .product-gamma-wrap-item {
      &:not(:last-child) {
        margin-right: 25px; } } }

  &.product_type_single_palette.coverings_title:not(.coverings_image) {
    .product-gamma-wrap:not(.product-gamma-wrap-palette) {
      .product-gamma-wrap-item {
        width: auto;
        height: auto;
        margin-bottom: 0; } } }
  .description-text {
    img {
      max-width: 100%;
      height: auto; } } }


.big-title {
  font-size: 2em;
  line-height: 43px;
  color: #39434E;
  font-family: $ffDinProBold;
  margin-bottom: 15px; }

.color-gamma {
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  font-family: $ffDinProRegular;
  //&::after
  //  content: ':'
  @include b(tablet) {
    margin-bottom: 15px; } }

.gallery-carousel {
  width: 100%;
  margin-bottom: 50px !important; }

.gallery-item {
  display: flex !important;
  width: 100%;
  //flex-wrap: wrap
  a {
    //width: 33.33%
    height: auto;
    min-height: 300px;
    background-size: cover;
    outline: none;
    max-height: 200px;
    img {
      width: 100%;
      height: 100%;
      padding-right: 5px;
      padding-bottom: 5px; } } }

.gallery-carousel {
  .slick-prev, .slick-next {
 }    //display: none !important
  .slick-dots {
    bottom: -30px;
    li button {
      background: #39434E;
      width: 8px;
      height: 8px;
      border-radius: 25px;
      &::before {
        content: ''; } }
    .slick-active button {
      background: #F33A20;
      width: 12px;
      height: 12px;
      border-radius: 25px; } } }


.narezka-wrap {
  background: #f7f7f7; }
.narezka-title {
  font-size: 12px;
  margin: 0 auto; }
.narezka-image {
  max-width: 134px;
  height: 80px;
  img {
    max-height: 100%; } }

.fancybox-caption__body {
  display: none !important; }

table {
  th,td {
    text-align: center; } }

.product-template {
  .fancybox-content {
    background: #fff; } }


.object-fit-cover, ~img {
  object-fit: cover; }

.desktop {
 &-max-height {

  @include b(desktop) {
    &-280 {
      max-height: 280px; } } } }



.product-thumbnail-box {
  //float: left
  .object-fit-cover {
    min-width: 440px; }
  .product-thumbnail {
    max-height: 100%;
    width: auto; } }
