@import '../vars', '../mixins/mixins';

//mobile-menu

.menu-mobile {
  &__wrapper {
    padding-bottom: 75px;
    position: fixed;
    box-shadow: 0 -2px 5px #bababa6b;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    overflow: auto;
    .lang {
      &.location-item {
        > a {
          font-size: 12px;
          font-weight: 600;
          color: #39434E;
          p {
            align-items: center; } } }
      .address-pop-up {
        right: -10px; } } }
  &__menu {
    &:not(:last-child) {}
    &.has-children {}
    > .menu-mobile__title {
      background: rgba(229, 229, 229, 0.54);
      //border-top: 1px solid $mainColor
      border-bottom: 1px solid $mainColor; } }
  &__list {
    .menu-mobile__parent:not(.show) {
      display: none; }
    //&.show
 }    //  display: block !important
  &__item {
    transition: all .7s ease;
    opacity: 1;
    transform: translateX(0%);
    &:not(:last-child) {
      .menu-mobile__title {
        border-bottom: 1px solid #DFDFDF; } }
    &.hide {
      opacity: 0;
      transform: translateX(-100%); } }
  &__title {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $ffDinProRegular;
    &-top {
      line-height: 3.5; } }
  &__link {
    color: #39434E;
    padding: 9px 0;
    flex-grow: 1;
    text-decoration: none;
    transition: color .3s ease;
    &:hover {
      color: $mainColor; } }
  &__arrow {
    cursor: pointer;
    &--in {
      height: 100%;
      padding: 6px 6px 6px 20px;
      svg {
        transform: rotate(-90deg); } }
    &--out {
      display: flex;
      border-radius: 50%;
      box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.18);
      margin-right: 1rem;
      width: 40px;
      height: 40px;
      transform: rotate(90deg); } }

  &__parent {} }

body:not(.home) {
  .main-menu-block {
    .site-button {
      &::before {
        @include b(desktop) {
          content: '';
          position: absolute;
          top: 100%;
          width: 100%;
          height: 13px; } }
      &:hover {
        & + .categories-menu {
          visibility: visible;
          opacity: 1;
          &>.categories-wrapper {
            display: flex; } } } } } }

.main-menu-block {
  .site-button {
    background: #F33A20;
    height: 65px;
    transition: all .25s ease;
    &:hover {
      background: #FF5841; }
    img,p {
      transition: all .25s ease; } } }


.main-menu-block {
  cursor: pointer;
  .site-button {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex; }
  p {
    font-family: $ffDinProMedium;
    font-size: 1.125em;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
    &:after {
      content: '';
      display: inline-block;
      width: 25px;
      height: 20px;
      background: url('../img/24x24/down.svg'); } } }

#menu-affix {
  transition: top .25s ease;
  &.affix {
    top: 2px;
    max-width: 275px;
    width: 100%;
    z-index: 99;
    .site-button {
      max-height: 34px;
      img {
        width: 24px;
        height: 24px; }
      p {
        font-size: 0.8em; } }
    .categories-menu {
      top: 34px !important;
      width: 100%; } } }

.categories-menu {
  //width: 275px
  // visibility: hidden
  // opacity: 0
  position: absolute;
  //top: 167px
  //position: fixed
  top: 75px;
  left: 0;
  right: auto;
  max-width: 1140px;
  width: $categoryItemWidth;
  transition: background-image .3s ease, opacity .3s ease;
  visibility: hidden;
  margin: 0 auto;
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.24), 0px 17px 50px rgba(0, 0, 0, 0.19);
  background-repeat: no-repeat;
  background-position: $categoryItemWidth center;
  background-size: cover;
  background-color: #fff;
  opacity: 0;
  z-index: 3;
  //&.unscroll
  //  opacity: 0 !important
  &.w100 {
    width: calc(100% - 30px);
 }    //background-position: $categoryItemWidth*2 center
  &.w33 {
    background-position: $categoryItemWidth center; }
  &.show {
    visibility: visible;
    opacity: 1;
    &>.categories-wrapper {
      display: flex; } }
  //&.top
  //  top: 10px
  //&.affix-top
  //  top: 167px
  &:hover {
    visibility: visible;
    opacity: 1;
    & > .categories-wrapper {
      display: flex; } } }

.categories-list {
  position: relative;
  min-width: $categoryItemWidth;
  &:hover {
    & > .categories-wrapper {
      display: flex; } } }

.categories-item {
  background: #fff;
  //width: $categoryItemWidth
  //visibility: hidden
  //opacity: 0
  &:hover {
    & > .categories-link {
      background: #EDEDED; } }
  &--has-child {
    &:hover {
      & > .categories-item {
        visibility: visible;
        opacity: 1; }
      & > .categories-wrapper {
        display: flex;
        visibility: visible;
        opacity: 1; } }
    .categories-link {
      position: relative;
      .icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg); } } }
  .categories-wrapper {
    position: absolute;
    top: 0;
    left: 100%; } }
.categories-wrapper.categories-wrapper_lvl-1 {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.categories-wrapper {
  //max-height: 480px
  //overflow-y: auto
  display: none;
  bottom: 0;
  //background: #fff
  transition: all .3s ease;
  &:hover {
    display: flex; }
  //  visibility: visible
  //  opacity: 1
  &_lvl-1 {}
  &_lvl-2 {
    .categories-item {
      padding-left: 20px; } }
  &_lvl-3 {} }

.categories-link {
  display: block;
  padding: 12px 0 12px 20px;
  text-transform: uppercase;
  font-family: $ffDinProRegular;
  font-size: 12px;
  border-bottom: 1px solid #DFDFDF;
  color: #39434E;
  transition: background-color .3s ease; }
