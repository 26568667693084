@mixin b($point) {
    @if $point == xxs {
        @media screen and (max-width: 460px) { @content ; }
    }
    @else if $point == xs {
        @media screen and (max-width: 767px) { @content ; }
    }
    @else if $point == sm {
        @media screen and (min-width: 768px) { @content ; }
    }
    @else if $point == md {
        @media screen and (min-width: 1100px) { @content ; }
    }
    @else if $point == lg {
        @media screen and (min-width: 1800px) { @content ; }
    }
    @else if $point == tablet {
        @media screen and (max-width: 991px) { @content ; }
    }
    @else if $point == desktop {
        @media screen and (min-width: 992px) { @content ; }
    }
    @else if $point == mdd {
        @media screen and (max-width: 1799px) { @content ; }
    }
    @else if $point == sd {
        @media screen and (min-width: 1101px) and (max-width: 1799px) { @content ; }
    }
    @else if $point == l {
        @media screen and (min-width: 1101px) and (max-width: 1400px) { @content ; }
    }
}

@mixin bc($map) {
    $directions: (min: min, max: max);
    $query: '';

    @each $direction, $breakpoint in $map {
        @if map_has_key($directions, $direction) {
            @if $query != '' {
                $query: $query + ') and (';
            }

            @if variable-exists(breakpoints) and map_has_key($breakpoints, $breakpoint) {
                $breakpoint: map_get($breakpoints, $breakpoint);
            }

            $query: $query + map_get($directions, $direction) + '-width: ' + $breakpoint + 'px';
        }
    }

    @if $query != '' {
        @media #{'(' + $query + ')'} {
            @content;
        }
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}