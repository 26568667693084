// media


.location-nav-list .location-item.location-all-coast,
.mobile-menu,
.mobile-news {
  display: none; }

.responsive {
  .slick-prev, .slick-next {
    display: none !important; } }

.multiple-items {
  .slick-prev, .slick-next {
    display: none !important; } }

.location-nav-list {
  justify-content: space-around;
  .location-item {
    max-width: max-content; } }

.header-top {
  background: #fff;
  transition: box-shadow .3s ease;
  z-index: 12;
  top: 0;
  &.affix {
    box-shadow: 0px 2px 10px #ededed; } }

.modal-inline {
  input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 23px;
    font-family: $ffDinProRegular;
    &::placeholder {
      font-size: 18px;
      line-height: 23px;
      color: #828282;
      font-family: $ffDinProRegular; }
    &:focus {
      border-color: #F33A20; } }
  .sub-title {
    font-family: $ffDinProMedium;
    font-size: 36px;
    line-height: 39px;
    color: #39434E;
    text-align: left; }
  .site-button {
    font-size: 18px;
    line-height: 20px;
    font-family: $ffDinProRegular;
    color: #fff;
    img {
      margin-left: 20px; } } }

.person-info-wrap {
  position: relative;
  p {
    position: absolute;
    right: 0;
    bottom: 35px;
    margin-bottom: 0;
    font-family: $ffDinProRegular;
    font-size: 10px;
    line-height: 12px;
    // identical to box height
    color: #828282; } }

#contact-us.modal-inline .call-btn {
  max-width: 185px; }

.product-value {
  font-size: 13px; }

.parametr-collapse .btn-link {
  text-align: left; }
@media (min-width: 1600px) {
  .advantages .top-banner-img {
    width: 100%; } }
@media (max-width: 1199px) {
  .items-menu {
    flex-wrap: wrap; }
  .categories-menu {
 }    //top: 125px
  .location-nav-list .location-item {
    &.location-coast {
      display: none; }
    &.location-all-coast {
      display: block; } }
  .top-block-wrap .top-banner-img {
    transform: translate(-20%, 0); }
  .location-nav-list {
    .location-item {
      max-width: max-content;
      display: flex;
      justify-content: space-around; } }
  .items-menu li p {
    font-size: 0.7em; }
  .home {
    .second-main-menu {
      margin: 0 auto 10px auto; } } }

@media screen and (max-width: 991px) {
  .header-top {
    margin-bottom: .5rem; }
  .lang {
    padding: 0!important; }
  header {
    padding-top: 15px; }
  .second-main-menu {
    .header-catalog {
      display: none; } }
  .top-block-wrap {
    margin-top: 15px;
    .container {
      padding-top: 35px; } }
  .back-call {
    display: none; }
  .main-logo {
    width: 225px; }
  .location-nav-list .location-item.location-all-coast {
    max-width: max-content; }
  .address-pop-up {
    top: 50px; }
  .lang .address-pop-up {
    top: 45px; }
  .mobile-menu {
    display: flex; }
  .location-info {
    margin-bottom: 15px; }
  .back-to-top-btn {
    right: 0;
    bottom: 0; }
  .breadcrumbs {
    margin-top: 80px; } }

@media screen and (max-width: 767px) {
  //.single-product:not(.product_type_without_thumb)
  //  .product-characteristic
  //    flex-direction: column-reverse
  .items-menu {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: #fff; }
    .single-item {
      margin-bottom: 1rem; } }
  .home .second-main-menu {
    margin-bottom: 0; }
  .footer-links {
    display: none; }
  #order-call .site-button {
    font-size: 12px;
    line-height: 14px;
    font-family: $ffDinProRegular;
    color: #fff;
    padding: 10px 20px; }
  .second-main-menu {
    padding-top: 10px;
 }    //padding-bottom: 10px
  .map {
    display: none; }
  .operators {
    border: none; }
  .location-button a {
    transform: rotate(90deg); }
  .loc-arrow {
    align-items: flex-end !important; }
  .contact-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 33px;
    margin-bottom: 20px;
    &:last-of-type {
      border-bottom: none; } }
  .about-house-icon {
    display: none; }
  .about-wrapper {
    flex-direction: column; }
  .about-wrap p {
    text-align: left !important; }
  .location-nav .location-item {
    display: none !important; }
  .location-nav-list {
    justify-content: flex-end;
    height: 100%; }
  .top-block-wrap {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 20px; }
  .section {
    padding-top: 20px; }
  .top-block-wrap {
    .container {
      padding-top: 0; } }
  .responsive .slick-slider {
    .slick-track, .slick-list {
      padding: 0 !important;
      padding-right: 50px !important; } }
  .multiple-items .slick-slider {
    .slick-track, .slick-list {
      padding: 0 !important;
      padding-right: 50px !important; } }
  .news-title {
    flex-direction: column; }
  .sub-title {
    font-size: 24px; }
  .to-all-articles {
    width: 100%;
    justify-content: flex-end; }
  .contacts h2 {
    margin-bottom: 24px; }
  .mobile-news {
    display: block; }
  .license {
    margin-bottom: 20px; }
  footer.section {
    padding: 30px 0; }
  .product-info {
    padding: 15px; }
  .parametr-collapse .btn-link {
    font-size: 13px; }
  .card-body, .product-info-text {
    font-size: 12px; }
  .product-cut {
    margin-top: 10px; }
  .product-title {
    font-size: 24px; }
  .product-values table td {
    font-size: 14px; }
  .advantages {
    .top-banner-img {
      width: auto!important;
      transform: translate(-25%); } } }

@media screen and (min-width: 992px) {
  .location-nav-list {
    align-items: flex-start; } }
