@import '../vars';

@-webkit-keyframes rotateLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes rotateLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

body:not(.mobile-on):after,body:not(.mobile-on):before {
  position: fixed;
  -webkit-transition: .4s ease;
  transition: .4s ease;
  opacity: 0;
  z-index: -1
}

body:not(.mobile-on):before {
  width: 100%;
  height: 100vh;
  background-color: #fff
}

body:not(.mobile-on):after {
  width: 120px;
  height: 120px;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-top: 2px solid $mainColor;
  border-radius: 50%
}

body.loader:not(.mobile-on):after,body.loader:not(.mobile-on):before {
  opacity: 1;
  z-index: 100
}

body.loader_rotate:not(.mobile-on):after,body.loader_rotate:not(.mobile-on):before {
  content: ""
}

body.loader_rotate:not(.mobile-on):after {
  -webkit-animation: rotateLoader .7s infinite linear;
  animation: rotateLoader .7s infinite linear
}