@import '../vars';
/*partner */

.partner-item img {
  object-fit: cover;
  margin: 0 auto;
  max-width: 100%;
  height: auto; }

.partners {
  .slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    height: 125px;
    padding: 0 10px; } }
