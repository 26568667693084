@import '../vars', '../mixins/_mixins';
/*top_block */

.top-block-wrap {
  background-image: url('../../dist/img/main_banner.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &::before {
    content: '';
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    padding-top: 3rem;
    padding-bottom: 3rem; }
  @include b(desktop) {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  @include b(tablet) {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .top-banner-img {
    position: absolute;
    z-index: -1;
    height: 100%;
    top: 0;
    /*transform: translate(-20%, 0); */
    width: auto;
    margin: 0 auto;
    left: 0;
    right: 0; }
  .container {
    padding-top: 20px; }
  h1 {
    font-family: $ffDinProBold;
    font-size: 2.688em;
    line-height: 43px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
    ~ p {
      font-family: $ffDinProRegular;
      font-size: 1.500em;
      line-height: 25px;
      text-align: center;
      color: #FFFFFF; } }
  input {
    width: 100%;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.19);
    padding-left: 20px;
    border: none; } }

button[type="submit"] {
  height: 60px;
  background: #F33A20;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24);
  border: none;
  color: #fff;
  &:hover {
    background: #FF5841; } }
button[type="submit"] p {
  font-family: $ffDinProMedium;
  font-size: 1.125em;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF; }
