.flex-list,
.flex-list-2,
.flex-list-1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  flex-wrap: wrap;
  a {
    text-decoration: none;
  }
  p {
    font-weight: bold;
    font-size: 12pt;
    font-family: Arial, sans-serif;
  }
  img {
    display: block;
    margin: 0 auto 10px auto;
  }
  div {
    margin-bottom: 1rem;
  }
}

.flex-list {
  div {
    width: 33%;
  }
  &-4 {
    div {
      width: 25%;
    }
  }
  &-2 {
    div {
      width: 50%;
    }
  }
  &-1 {
    div {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .flex-list {
    div {
      width: 50%;
    }
    &-4, &-2 {
      div {
        width: 50%;
      }
    }
    &-1 {
      div {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .flex-list {
    div {
      width: 100%;
    }
    &-1, &-2, &-4 {
      div {
        width: 100%;
      }
    }
  }
}