// Эти файлы от сюда не убирай, регистрируй переменные и юзай миксины
//@import "./mixins/_mixins.scss"
//@import "./_normalize.scss"
//@import "./_vars.sass"
//@import "./blocks/loader.scss"
//@import "./bootstrap/bootstrap-4/bootstrap.scss"
//@import '_common.sass'

//@import "./_old/_fancybox.scss"
//@import "./_basic.sass"
//@import './_old/_ui.sass'
//@import './_old/_category.sass'
//@import './_old/_single.sass'
//@import './_old/_header.sass'
//@import './_old/_footer.sass'
//@import './_old/_front-page.sass'
//@import './_old/_page-breadcrumbs.sass'
//@import '_old/_pagination.sass'
//@import '_old/_page-content'
//@import '_old/_gallery'
//@import '_old/_partners'
//@import '_old/_contacts'
//@import '_old/_genetics'
//@import './_old/_zitnica-other.sass'
//@import './_old/_404.sass'
//@import './_old/_search.sass'

@import './vars';
@import '_fonts';
@import 'home/header';
@import 'home/main-banner';
@import 'home/info-block';
@import 'home/news';
@import 'home/reviews';
@import 'home/partners';
@import 'home/contacts';
@import 'home/footer';

@import 'pages/catalog';
@import 'pages/single-product';
@import 'pages/single-page';
@import 'blocks/mobile-menu';
@import 'blocks/menu';
@import 'blocks/loader';
@import 'blocks/flex-list';
//@import 'blocks/fancybox.3.5.7.min'
//@import './bootstrap/bootstrap.min'

.position {
  &-relative {
    position: relative; }
  &-absolute {
    position: absolute; }
  &-static {
    position: static; } }

.affix {
  position: fixed; }
//
//.affix-top
//  top: 0

.cursor-hand {
  cursor: pointer; }

.header-grow {
  position: relative !important; }

html.lock, body.lock {
  overflow: hidden; }

html,
body {
  height: 100%;
  font-family: $ffDinProRegular; }

.image-mx-auto img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.img-responsive,
.img-responsive img {
  max-width: 100%;
  height: auto; }

.text-center {
  text-align: center; }

.body__content {
  display: flex;
  flex-direction: column;
  height: 100%; }

.body__wrapper {
  flex: 1 0 auto; }

#footer-main {
  flex: 0 0 auto; }


body, html {
  font-size: 16px; }

body, nav, ul, li, a {
  margin: 0;
  padding: 0; }

.section {
  padding-top: 4.375rem;
  padding-bottom: 5.625rem;
  .contacts {
    position: relative; } }

.sub-title {
  font-family: $ffDinProBold;
  font-size: 2.688em;
  line-height: 43px;
  text-align: center;
  color: #39434E;
  margin-bottom: 30px; }

.title {
  font-family: $ffDinProBold;
  font-size: 1.125em;
  line-height: 23px;
  text-align: left;
  color: #39434E;
  margin-bottom: 0;
  text-transform: uppercase; }

.btn-main {
  padding: 10px 15px;
  display: inline-block;
  background: $mainColor;
  color: #fff !important;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24);
  font-size: 16px !important;
  font-weight: normal;
  transition: all .3s ease;
  user-select: none;
  &:hover {
    background: #FF5841;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14); } }

.white-title {
  color: #fff; }

body {
  position: relative;
  &:not(.home) {
    #footer-main {
      margin-top: 3rem; } } }
.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 10;
  background-size: cover;
  display: none;
  &.visible {
    display: block; }
  a {
    background: url('../img/icons/circleup.svg') no-repeat;
    width: 100px;
    height: 100px;
    display: block;
    transition: all 0.3s; } }

.hidden {
  display: none; }

.cursor {
  &_default {
    cursor: default !important; } }

button[type="submit"] {
  &::after {
    content: '';
    width: 12%;
    height: inherit;
    background: url('../img/icons/send.svg') center no-repeat; } }

.wpcf7-form {
  br {
    display: none; }
  input {
    min-height: 54px;
    box-sizing: border-box; }
  .wpcf7-form-control-wrap {
    position: relative;
    display: block;
    margin-bottom: 15px; }
  .wpcf7-response-output {
    margin: 0 !important; }
  &.invalid {
    position: relative;
    .wpcf7-not-valid-tip {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 1px;
      line-height: 1;
      font-size: 14px; } }
  .wpcf7-response-output {
    background: #fff;
    position: absolute;
    font-size: 14px;
    &:not(.wpcf7-mail-sent-ok) {
      border: 1px solid $mainColor; } } }


.fancybox-content {
  .fancybox-close-small path {
    fill: $mainColor; }
  .wpcf7-form {
    padding-bottom: 70px;
    .wpcf7-form-control-wrap {
      margin-bottom: 25px; }
    &.invalid {
      .wpcf7-not-valid-tip {
        top: 100%; }
      .wpcf7-response-output {
        position: absolute; } } } }

@import "common.scss";
@import "_media";
