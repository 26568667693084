@import '../vars';
/*footer */

footer {
  background: #F5F5F5;
  .footer-title, .footer-link {
    font-family: $ffDinProBold;
    font-size: 1.500em;
    line-height: 25px;
    color: #39434E;
    margin-bottom: 22px; }
  .footer-link:hover {
    text-decoration: underline; }
  ul li a {
    font-family: $ffDinProRegular;
    font-size: 0.875em;
    line-height: 16px;
    color: #39434E; } }

.license {
  .logo img {
    width: 78px;
    height: 16px; }
  p {
    font-family: $ffDinProRegular;
    font-size: 0.75rem;
    line-height: 15px;
    color: #000; } }

.rights-block {
  margin-top: 40px; }

/*map */

.contacts {
  position: relative; }

.map {
  width: 100%;
  height: 100%;
  //position: absolute
  //right: 0
 }  //top: 0

#map {
  z-index: 1; }

#map,.nav.main {
  width: 100%;
  height: 100%; }

.opt {
  display: flex;
  padding: 15px 30px;
  background: #F5F5F5;
  div {
    display: flex;
    flex-direction: column; }
  span {
    padding-left: 0 !important;
    color: #39434E !important;
    text-transform: uppercase;
    font-family: $ffDinProMedium;
    font-size: 0.750rem; }
  a {
    padding: 0 !important;
    font-family: $ffDinProBold;
    color: #39434E !important;
    font-size: 1.500rem;
    line-height: 26px; } }
