@import '../vars';
/*about */

.advantages {
  position: relative;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-background-size: contain;
  background-size: contain;
  overflow: hidden;
  .top-banner-img {
    position: absolute;
    z-index: -1;
    width: auto;
    top: 0;
    height: 100%; } }

.house-logo {
  width: 40px;
  height: 16px;
  background: url('../img/logo icon.svg') no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  margin: 0 auto; }

.item {
  img {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    margin-right: 20px; }
  padding: 0 30px; }

.plus_block {
  p {
    color: #39434E; }
  img {
    margin-top: 45px; } }

.advantages {
  p {
    color: #fff; }
  img {
    object-fit: cover; } }

.plus_block,
.advantages {
  p {
    font-family: $ffDinProRegular;
    line-height: 18px;
    text-align: center;
    margin-top: 20px; } }


.about {
  margin-bottom: 50px; }

.about-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px; }

.about-wrap {
  flex: 1; }

.about-house-icon {
  padding: 0 20px;
  max-width: 100px; }
