@import '../vars', '../mixins/mixins';
/*contacts */

.contacts {
  background: #39434E;
  color: #fff !important;
  h2 {
    color: #fff;
    margin-bottom: 60px; }
  .address {
    svg {
      margin-right: 10px; } } }

.location-button {
  @include b(xs) {
    display: none !important; }
  a {
    background: #F33A20;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../img/icons/arrow_right-2.svg');
    margin: 0 auto;
    background-position: 50%; } }

.address {
  .location {
    font-family: $ffDinProRegular;
    font-size: 1.08em;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0; }
  .station {
    font-family: $ffDinProRegular;
    font-size: 0.750em;
    line-height: 15px;
    text-align: left; } }

.operators {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.15); }

.operators-list {
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0; } }
  a {
    color: #fff;
    display: flex;
    align-items: center;
    &.life:before {
      content: '';
      display: inline-block;
      background: url('../img/icons/mobile/Lifewee.svg') no-repeat;
      width: 25px;
      height: 25px;
      margin-right: 20px; }
    &.mts:before {
      content: '';
      display: inline-block;
      background: url('../img/icons/mobile/Ve22ctor.svg') no-repeat;
      width: 25px;
      height: 25px;
      margin-right: 20px; }
    &.kievstar:before {
      content: '';
      display: inline-block;
      background: url('../img/icons/mobile/kiewewevstar.svg') no-repeat;
      width: 25px;
      height: 25px;
      margin-right: 20px; } } }

.contact-item {
  margin-bottom: 50px; }

.contacts .contact-us-btn {
  width: 100%;
  max-width: 280px;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: #F33A20;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border: none;
  color: #fff;
  text-transform: uppercase;
  &:hover {
    background-color: #FF5841; }
  p {
    font-family: $ffDinProMedium;
    font-size: 1.125em;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF; } }

@media screen and (min-width: 1200px) {
  .form_inner.in-one-line {
    max-width: 100%;
    width: 100%;
    form {
      & > p {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 0;
        .wpcf7-form-control-wrap {
          margin-bottom: 0;
          width: 33%; }
        button[type="submit"] {
          width: 30%;
          height: 54px; } } } } }
