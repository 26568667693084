/*presets */
@font-face {
  font-family: $ffDinProMedium;
  src: url("../fonts/DINPro-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DINPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Medium.otf") format("opentype"), url("../fonts/DINPro-Medium.svg") format("svg"), url("../fonts/DINPro-Medium.ttf") format("truetype"), url("../fonts/DINPro-Medium.woff") format("woff"), url("../fonts/DINPro-Medium.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: $ffDinProBold;
  src: url("../fonts/DINPro-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DINPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Bold.otf") format("opentype"), url("../fonts/DINPro-Bold.svg") format("svg"), url("../fonts/DINPro-Bold.ttf") format("truetype"), url("../fonts/DINPro-Bold.woff") format("woff"), url("../fonts/DINPro-Bold.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: $ffDinProRegular;
  src: url("../fonts/DINPro-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DINPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Regular.otf") format("opentype"), url("../fonts/DINPro-Regular.svg") format("svg"), url("../fonts/DINPro-Regular.ttf") format("truetype"), url("../fonts/DINPro-Regular.woff") format("woff"), url("../fonts/DINPro-Regular.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal; }

