@charset "UTF-8";
/*presets */
@font-face {
  font-family: "DINPro-Medium";
  src: url("../fonts/DINPro-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DINPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Medium.otf") format("opentype"), url("../fonts/DINPro-Medium.svg") format("svg"), url("../fonts/DINPro-Medium.ttf") format("truetype"), url("../fonts/DINPro-Medium.woff") format("woff"), url("../fonts/DINPro-Medium.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINPro-Bold";
  src: url("../fonts/DINPro-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DINPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Bold.otf") format("opentype"), url("../fonts/DINPro-Bold.svg") format("svg"), url("../fonts/DINPro-Bold.ttf") format("truetype"), url("../fonts/DINPro-Bold.woff") format("woff"), url("../fonts/DINPro-Bold.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINPro-Regular";
  src: url("../fonts/DINPro-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DINPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Regular.otf") format("opentype"), url("../fonts/DINPro-Regular.svg") format("svg"), url("../fonts/DINPro-Regular.ttf") format("truetype"), url("../fonts/DINPro-Regular.woff") format("woff"), url("../fonts/DINPro-Regular.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/*nav */
.header-row {
  display: flex;
  position: relative;
}

@media screen and (min-width: 992px) {
  .header-row .header-col-3 {
    min-width: 275px;
  }
}

.header-row .header-grow {
  flex-grow: 1;
}

.header-info {
  margin-left: auto !important;
}

.location-nav-list .location-item.first-item-menu li a {
  flex: 1;
}

.location-item.lang:hover p {
  color: #F33A20 !important;
}

.location-item:hover .location-wrap p, .location-item:hover .location-wrap span {
  color: #F33A20 !important;
  fill: #F33A20;
}

.location-item:hover .nav-list svg path, .location-item:hover .nav-list svg circle {
  color: #F33A20 !important;
  fill: #F33A20;
}

.location-nav-list .location-item {
  position: relative;
  float: left;
  border-right: 1px solid #DFDFDF;
  padding-bottom: 15px;
  padding: 0;
}

.location-nav-list .location-item:not(.lang) a {
  display: flex;
}

.location-nav-list .location-item.lang a {
  display: inline-block;
}

.location-nav-list .location-item a {
  padding: 10px 15px;
  color: #39434E;
}

.location-item {
  position: relative;
}

.address-pop-up {
  position: absolute;
  z-index: 200;
  top: 60px;
  left: -50%;
  background: #fff;
  width: max-content;
  transition: all 0.2s ease;
  transform: translateY(10%);
  visibility: hidden;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
  opacity: 0;
}

.address-pop-up:before {
  content: '';
  width: 50px;
  height: 50px;
  background: #fff;
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);
  top: -5px;
}

.lang .address-pop-up:before {
  content: '';
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  z-index: -1;
  left: unset;
  transform: translate(0, 0) rotate(45deg);
  top: -5px;
  right: 7px;
}

.address-details {
  padding: 15px;
  max-width: 330px;
}

.main-logo {
  max-width: 275px;
  width: 100%;
  transition: all 0.3s ease;
}

.lang {
  max-width: 90px;
  padding: 0 15px 15px 15px !important;
}

.lang > a {
  margin-left: 0;
  display: flex;
  justify-content: flex-end;
}

.location-nav-list .nav-list {
  flex-direction: row;
  align-items: normal;
  justify-content: flex-start;
}

.nav.location-nav-list > li.hover > ul {
  top: 50px;
}

.nav li {
  background: #fff;
}

.location-nav-list .nav-list p {
  font-size: 1.125em;
}

.location-nav-list .nav-list span {
  font-family: "DINPro-Regular";
  font-size: 0.625em;
  line-height: 12px;
  color: #828282;
  text-transform: uppercase;
}

.location-nav-list > li.lang > .nav-list p {
  font-family: "DINPro-Medium";
  font-size: 0.6875rem;
  color: #39434E;
  line-height: 12px;
  margin-top: 5px;
}

.location-nav-list > li.lang > .nav-list p:after {
  margin-top: -6px;
}

.location-nav-list > li {
  border: none !important;
}

.location-nav-list > li > .nav-list img {
  height: 21px;
  margin-right: 10px;
}

.nav-list p {
  display: flex;
  justify-content: space-between;
  font-family: "DINPro-Regular";
  line-height: 17px;
}

.nav-list p:after {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../img/24x24/toolbar/arrowdown.svg") no-repeat;
}

.location-nav .address-details p, .location-nav .address-details span, .location-nav .address-details li, .location-nav .address-details a {
  border-bottom: none;
  padding: 0;
  padding-bottom: 15px;
  font-size: 1.125rem;
  line-height: 23px;
  display: flex;
}

.location-nav .address-details p {
  padding-bottom: 0;
  display: flex;
  line-height: 23px;
  padding-left: 43px;
}

.location-nav .address-details p:before {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/menu/location.svg") no-repeat;
  width: 28px;
  height: 28px;
  left: 15px;
}

.location-nav .address-details span {
  padding-left: 45px;
  color: #828282;
  font-size: 0.750em;
  background: #fff;
  z-index: 1;
  width: max-content;
}

.location-nav > li.hover > .lang-details {
  left: -20px;
}

.lang-details {
  background: #fff;
  z-index: 1;
  width: max-content;
  box-shadow: 0px 25px 55px rgba(0, 0, 0, 0.19);
}

.lang-details li {
  width: 100%;
  background: #fff;
  z-index: 1;
  border-bottom: 1px solid #DFDFDF;
}

.lang-details li a {
  border: none !important;
  text-transform: uppercase;
  text-align: left;
  padding: 20px !important;
  font-size: 0.75rem;
  line-height: 13px;
  font-family: "DINPro-Medium";
  color: #39434E;
}

.lang-details li:last-child {
  border-bottom: none;
}

.lang-details li a:hover {
  color: #F33A20;
}

.location-nav .address-details li {
  padding: 20px 100px 20px 35px;
  width: max-content;
  background: #fff;
  z-index: 1;
}

.location-nav .address-details a:before {
  content: '';
  width: 28px;
  height: 28px;
  margin-right: 15px;
  display: inline-block;
}

.location-nav .address-details a.life:before {
  background: url("../img/icons/mobile/Life.svg") no-repeat;
}

.location-nav .address-details a.mts:before {
  background: url("../img/icons/mobile/Vector.svg") no-repeat;
}

.location-nav .address-details a.kievstar:before {
  background: url("../img/icons/mobile/kievstar.svg") no-repeat;
}

.call-btn {
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: #F33A20;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24);
  border: none;
  text-transform: uppercase;
  min-width: 200px;
  max-height: 45px;
}

.call-btn p {
  font-family: "DINPro-Medium";
  font-size: 0.813em;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
}

.items-menu li p {
  font-size: 0.75rem;
  line-height: 13px;
  text-transform: uppercase;
  font-family: "DINPro-Medium";
  padding: 0 22px;
  margin-top: 5px;
}

.partner-item {
  transition: all .3s ease;
  border-right: 1px solid #DFDFDF;
}

.partner-item:hover, .partner-item:focus {
  opacity: .6;
}

.site-button {
  transition: all 0.3s ease;
}

.site-button:hover {
  background-color: #FF5841;
}

.site-button:focus {
  background-color: #CC1900;
  box-shadow: none;
  outline: none;
}

.arrow_down {
  fill: #F33A20;
}

.location-item svg, .items-menu > li svg {
  fill: #39434E;
}

.location-item:hover svg, .items-menu > li:hover svg {
  fill: #F33A20;
}

.nav.items-menu > li:active svg {
  fill: #F33A20;
}

.items-menu > li > a:focus svg,
.items-menu > li.active > a svg,
.location-item a:focus svg {
  fill: #CC1900;
}

.items-menu > li:hover p,
.nav.items-menu > li:active p {
  color: #F33A20;
}

.items-menu > li > a:focus p,
.items-menu > li.active > a p {
  color: #CC1900;
}

.location-nav-list > li > .nav-list:hover svg path, .location-nav-list > li > .nav-list:hover svg circle {
  fill: #F33A20;
}

.location-nav-list > li > .nav-list:hover p {
  color: #F33A20;
}

.location-nav-list > li > .nav-list:focus svg path, .location-nav-list > li > .nav-list:focus svg circle {
  fill: #CC1900;
}

.location-nav-list > li > .nav-list:focus p {
  color: #CC1900;
}

.opt {
  transition: all 0.3s ease;
  cursor: pointer;
}

.opt div:hover a, .opt div:hover span {
  color: #F33A20 !important;
}

.opt div:focus a, .opt div:focus span {
  color: #CC1900 !important;
}

.opt svg {
  margin-right: 20px;
}

.single-item {
  position: relative;
  flex: 1;
}

.single-item a {
  display: flex;
  align-items: normal;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.single-item a svg {
  margin: 0 auto;
}

.single-item svg.arrow_down {
  position: absolute;
  display: block;
  top: calc(100% - 7px);
  left: calc(50% - 12px);
}

.first-item-menu {
  display: none;
}

.single-item:hover .first-item-menu {
  display: flex;
  visibility: visible;
  opacity: 1;
  position: absolute;
  width: max-content;
  left: 0;
  z-index: 10;
  flex-direction: column;
  background: #fff;
  min-width: 100%;
  box-shadow: 0px 25px 55px rgba(0, 0, 0, 0.19);
}

.single-item.active a {
  color: red;
}

.items-menu .single-item {
  border-right: 1px solid #DFDFDF;
}

.items-menu .single-item:nth-child(1) {
  border-left: none;
}

.items-menu .single-item:last-child {
  border-right: none;
  border-bottom: none;
}

.items-menu .single-item p {
  color: #39434E;
}

.sub-item-menu {
  display: none;
}

.first-item-menu li {
  position: relative;
  font-size: 0.75rem;
  line-height: 13px;
  text-transform: uppercase;
  font-family: "DINPro-Medium";
}

.first-item-menu li:hover .down_arror {
  transform: translateY(-50%) rotate(180deg);
}

.first-item-menu li a {
  position: relative;
  color: #39434E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px 15px 20px;
  border-bottom: 1px solid #DFDFDF;
  font-family: "DINPro-Regular";
}

.first-item-menu li:hover {
  background: #F5F5F5;
}

.first-item-menu a {
  text-align: left;
}

.first-item-menu li:hover .sub-item-menu {
  display: flex;
  flex-direction: column;
  width: max-content;
  background: #fff;
  min-width: 100%;
}

.down_arror {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  background: url("../img/24x24/toolbar/arrowdown.svg") no-repeat;
  width: 25px;
  height: 25px;
  transition: .2s ease;
}

.sub-item-menu li a {
  padding-left: 40px;
}

.location-item:hover .address-pop-up {
  opacity: 1;
  transform: translateY(0%);
  visibility: visible;
}

.lang .address-pop-up {
  top: 100%;
  width: 114px;
  right: 0;
  left: unset;
}

.address {
  display: flex;
  align-items: normal;
}

.address svg {
  margin-right: 15px;
  min-width: 16px;
}

@media screen and (min-width: 992px) {
  .second-main-menu {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .second-main-menu {
    border-bottom: 1px solid #ececec;
  }
}

.top-block-wrap h1 {
  margin-top: 1.5rem;
}

header.scroll {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06), 0px 2px 5px rgba(0, 0, 0, 0.16);
  padding-top: 15px;
}

header.scroll .main-logo {
  width: 160px;
}

.page-template-default table {
  border: 3px double grey !important;
}

.page-template-default table tr, .page-template-default table td {
  border-bottom: 1px solid grey !important;
}

.page-template-default table tr td[rowspan], .page-template-default table td td[rowspan] {
  border: 2px dashed grey !important;
}

/*top_block */
.top-block-wrap {
  background-image: url("../../dist/img/main_banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.top-block-wrap::before {
  content: '';
  top: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 992px) {
  .top-block-wrap {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 991px) {
  .top-block-wrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.top-block-wrap .top-banner-img {
  position: absolute;
  z-index: -1;
  height: 100%;
  top: 0;
  /*transform: translate(-20%, 0); */
  width: auto;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.top-block-wrap .container {
  padding-top: 20px;
}

.top-block-wrap h1 {
  font-family: "DINPro-Bold";
  font-size: 2.688em;
  line-height: 43px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.top-block-wrap h1 ~ p {
  font-family: "DINPro-Regular";
  font-size: 1.500em;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
}

.top-block-wrap input {
  width: 100%;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.19);
  padding-left: 20px;
  border: none;
}

button[type="submit"] {
  height: 60px;
  background: #F33A20;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24);
  border: none;
  color: #fff;
}

button[type="submit"]:hover {
  background: #FF5841;
}

button[type="submit"] p {
  font-family: "DINPro-Medium";
  font-size: 1.125em;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}

/*about */
.advantages {
  position: relative;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-background-size: contain;
  background-size: contain;
  overflow: hidden;
}

.advantages .top-banner-img {
  position: absolute;
  z-index: -1;
  width: auto;
  top: 0;
  height: 100%;
}

.house-logo {
  width: 40px;
  height: 16px;
  background: url("../img/logo icon.svg") no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  margin: 0 auto;
}

.item {
  padding: 0 30px;
}

.item img {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin-right: 20px;
}

.plus_block p {
  color: #39434E;
}

.plus_block img {
  margin-top: 45px;
}

.advantages p {
  color: #fff;
}

.advantages img {
  object-fit: cover;
}

.plus_block p,
.advantages p {
  font-family: "DINPro-Regular";
  line-height: 18px;
  text-align: center;
  margin-top: 20px;
}

.about {
  margin-bottom: 50px;
}

.about-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
}

.about-wrap {
  flex: 1;
}

.about-house-icon {
  padding: 0 20px;
  max-width: 100px;
}

/*news */
.to-all-articles {
  display: flex;
  align-items: center;
  justify-content: center;
}

.to-all-articles span {
  font-family: "DINPro-Medium";
  font-size: 0.813em;
  line-height: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #39434E;
}

.news-item {
  padding: 15px;
  transition: all 0.3s ease;
}

.news-item:hover, .news-item:focus {
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.24);
}

@media screen and (min-width: 992px) {
  .news-item .image-wrap img {
    width: 100%;
    height: 160px;
  }
}

@media screen and (max-width: 991px) {
  .news-item .image-wrap img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 460px) {
  .news-item .image-wrap img {
    margin: 0 auto;
    display: block;
  }
}

.news-item .category {
  font-family: "DINPro-Regular";
  font-size: 0.750em;
  line-height: 15px;
  color: #39434E;
  padding-left: 14px;
  border-left: 1px solid #39434E;
}

.news-item .article-title {
  display: block;
  font-family: "DINPro-Medium";
  font-size: 1.125em;
  line-height: 1.2;
  text-align: left;
  color: #39434E;
  margin-top: 10px;
  margin-bottom: 0 !important;
  transition: color .3s ease;
}

.news-item .article-title:hover {
  color: #CC1900 !important;
}

.news-item p {
  font-family: "DINPro-Regular";
  font-size: 0.875em;
  line-height: 18px;
  color: #39434E;
  margin: 10px 0;
  word-break: break-word;
}

.news-item .category-btn {
  border: 1px solid #39434E;
  font-family: "DINPro-Medium";
  font-size: 0.8125rem;
  line-height: 14px;
  color: #39434E;
  padding: 10px 13px 10px 20px;
  text-transform: uppercase;
}

/*reviews */
.reviews {
  background: #F5F5F5;
}

.reviews .name {
  font-family: "DINPro-Medium";
  font-size: 1em;
  line-height: 18px;
  text-align: left;
  color: #39434E;
  margin-bottom: 10px;
}

.reviews .profession {
  font-family: "DINPro-Regular";
  font-size: 1em;
  line-height: 18px;
  text-align: left;
  color: #39434E;
}

.reviews .item p {
  font-family: "DINPro-Regular";
  font-size: 0.875em;
  line-height: 18px;
  color: #39434E;
  margin-top: 20px;
}

/*slick main page */
.responsive .slick-dots, .multiple-items .slick-dots {
  display: flex;
  background: #DFDFDF;
  height: 4px;
}

.responsive .slick-dots li, .multiple-items .slick-dots li {
  flex: 1;
  background: #DFDFDF;
  height: 4px;
  margin: 0;
}

.responsive .slick-dots li.slick-active, .multiple-items .slick-dots li.slick-active {
  flex: 1;
  background: #F33A20;
  height: 4px;
}

/*partner */
.partner-item img {
  object-fit: cover;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.partners .slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  height: 125px;
  padding: 0 10px;
}

/*contacts */
.contacts {
  background: #39434E;
  color: #fff !important;
}

.contacts h2 {
  color: #fff;
  margin-bottom: 60px;
}

.contacts .address svg {
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .location-button {
    display: none !important;
  }
}

.location-button a {
  background: #F33A20;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../img/icons/arrow_right-2.svg");
  margin: 0 auto;
  background-position: 50%;
}

.address .location {
  font-family: "DINPro-Regular";
  font-size: 1.08em;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0;
}

.address .station {
  font-family: "DINPro-Regular";
  font-size: 0.750em;
  line-height: 15px;
  text-align: left;
}

.operators {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.operators-list li {
  margin-bottom: 10px;
}

.operators-list li:last-child {
  margin-bottom: 0;
}

.operators-list a {
  color: #fff;
  display: flex;
  align-items: center;
}

.operators-list a.life:before {
  content: '';
  display: inline-block;
  background: url("../img/icons/mobile/Lifewee.svg") no-repeat;
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.operators-list a.mts:before {
  content: '';
  display: inline-block;
  background: url("../img/icons/mobile/Ve22ctor.svg") no-repeat;
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.operators-list a.kievstar:before {
  content: '';
  display: inline-block;
  background: url("../img/icons/mobile/kiewewevstar.svg") no-repeat;
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.contact-item {
  margin-bottom: 50px;
}

.contacts .contact-us-btn {
  width: 100%;
  max-width: 280px;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: #F33A20;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border: none;
  color: #fff;
  text-transform: uppercase;
}

.contacts .contact-us-btn:hover {
  background-color: #FF5841;
}

.contacts .contact-us-btn p {
  font-family: "DINPro-Medium";
  font-size: 1.125em;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}

@media screen and (min-width: 1200px) {
  .form_inner.in-one-line {
    max-width: 100%;
    width: 100%;
  }
  .form_inner.in-one-line form > p {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .form_inner.in-one-line form > p .wpcf7-form-control-wrap {
    margin-bottom: 0;
    width: 33%;
  }
  .form_inner.in-one-line form > p button[type="submit"] {
    width: 30%;
    height: 54px;
  }
}

/*footer */
footer {
  background: #F5F5F5;
}

footer .footer-title, footer .footer-link {
  font-family: "DINPro-Bold";
  font-size: 1.500em;
  line-height: 25px;
  color: #39434E;
  margin-bottom: 22px;
}

footer .footer-link:hover {
  text-decoration: underline;
}

footer ul li a {
  font-family: "DINPro-Regular";
  font-size: 0.875em;
  line-height: 16px;
  color: #39434E;
}

.license .logo img {
  width: 78px;
  height: 16px;
}

.license p {
  font-family: "DINPro-Regular";
  font-size: 0.75rem;
  line-height: 15px;
  color: #000;
}

.rights-block {
  margin-top: 40px;
}

/*map */
.contacts {
  position: relative;
}

.map {
  width: 100%;
  height: 100%;
}

#map {
  z-index: 1;
}

#map, .nav.main {
  width: 100%;
  height: 100%;
}

.opt {
  display: flex;
  padding: 15px 30px;
  background: #F5F5F5;
}

.opt div {
  display: flex;
  flex-direction: column;
}

.opt span {
  padding-left: 0 !important;
  color: #39434E !important;
  text-transform: uppercase;
  font-family: "DINPro-Medium";
  font-size: 0.750rem;
}

.opt a {
  padding: 0 !important;
  font-family: "DINPro-Bold";
  color: #39434E !important;
  font-size: 1.500rem;
  line-height: 26px;
}

#color-popup {
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

#color-popup .color {
  height: 300px;
  width: 300px;
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: contain;
}

@media screen and (max-width: 991px) {
  #color-popup .color {
    height: 220px;
    width: 220px;
  }
}

#color-popup .color-code {
  font-size: 2.4rem;
}

#color-popup .color-title,
#color-popup .color-code {
  color: white;
  mix-blend-mode: difference;
}

.fancybox-bg {
  background: rgba(0, 0, 0, 0.6);
}

.fancybox-slide--html .fancybox-close-small {
  padding: 0;
  right: 20px;
  top: 20px;
  width: 45px;
  height: 45px;
}

.fancybox-slide--html .fancybox-close-small path {
  fill: #fff;
}

/* scrollbar fix jumping */
.compensate-for-scrollbar .header-top,
.compensate-for-scrollbar .back-to-top-btn {
  padding-right: 15px;
}

.color-button-wrapper {
  display: flex;
  width: 100%;
}

.color-button {
  position: absolute;
  height: calc(100% - 54px);
  bottom: 0;
  width: 50%;
  line-height: 36px;
  text-align: center;
  color: #fff;
  flex-grow: 1;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
}

.color-button:focus, .color-button:hover {
  color: #fff;
}

.color-button::before, .color-button::after {
  mix-blend-mode: difference;
  position: absolute;
  top: 35%;
}

@media screen and (max-width: 767px) {
  .color-button::before, .color-button::after {
    top: 35%;
  }
}

.color-button-prev {
  left: 44px;
}

.color-button-prev::before {
  left: 0;
  content: '←';
}

.color-button-next {
  right: 44px;
}

.color-button-next::after {
  right: 0;
  content: '→';
}

.category-banner {
  display: block;
  padding-bottom: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.category-banner.fat {
  padding-bottom: 33%;
}

.product-info {
  padding: 3%;
}

@media screen and (min-width: 768px) {
  .product-info {
    min-height: 250px;
  }
}

@media screen and (min-width: 992px) {
  .product-info {
    min-height: 300px;
  }
}

.product-wrap {
  margin-bottom: 30px;
}

.single-product-item {
  background: #F5F5F5;
  margin: 0 auto 10px auto;
  transition: all .3s;
}

@media screen and (min-width: 992px) {
  .single-product-item .product-image .image-wrap a {
    height: 300px;
    overflow: hidden;
  }
  .single-product-item .product-image:not(.with-coverings) img {
    max-height: 100%;
  }
}

@media screen and (max-width: 460px) {
  .single-product-item {
    max-width: 300px;
  }
}

.single-product-item:hover {
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.24), 0px 25px 55px rgba(0, 0, 0, 0.19);
  background: #fff;
}

.single-product-item:hover .product-image-link {
  color: #fff;
  background: #F33A20;
}

@media screen and (max-width: 767px) {
  .single-product-item .slider-for {
    max-height: 250px;
  }
}

.single-product-item.without-link, .single-product-item.with-html-mode {
  background: #fff;
  border: 1px solid #e3e3e3;
}

.single-product-item.without-link .product-info, .single-product-item.with-html-mode .product-info {
  padding: 10px 20px;
}

@media screen and (max-width: 767px) {
  .single-product-item.without-link .product-info-wrapped, .single-product-item.with-html-mode .product-info-wrapped {
    display: none;
  }
}

.single-product-item.without-link table,
.single-product-item.without-link .table-main,
.single-product-item.with-html-mode table,
.single-product-item.with-html-mode .table-main,
.product-characteristic table,
.product-characteristic .table-main {
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
}

.single-product-item.without-link table th, .single-product-item.without-link table td, .single-product-item.without-link table .table-row .table-col,
.single-product-item.without-link .table-main th,
.single-product-item.without-link .table-main td,
.single-product-item.without-link .table-main .table-row .table-col,
.single-product-item.with-html-mode table th,
.single-product-item.with-html-mode table td,
.single-product-item.with-html-mode table .table-row .table-col,
.single-product-item.with-html-mode .table-main th,
.single-product-item.with-html-mode .table-main td,
.single-product-item.with-html-mode .table-main .table-row .table-col,
.product-characteristic table th,
.product-characteristic table td,
.product-characteristic table .table-row .table-col,
.product-characteristic .table-main th,
.product-characteristic .table-main td,
.product-characteristic .table-main .table-row .table-col {
  line-height: 1.15;
}

.single-product-item.without-link table th:nth-child(odd), .single-product-item.without-link table td:nth-child(odd), .single-product-item.without-link table .table-row .table-col:nth-child(odd),
.single-product-item.without-link .table-main th:nth-child(odd),
.single-product-item.without-link .table-main td:nth-child(odd),
.single-product-item.without-link .table-main .table-row .table-col:nth-child(odd),
.single-product-item.with-html-mode table th:nth-child(odd),
.single-product-item.with-html-mode table td:nth-child(odd),
.single-product-item.with-html-mode table .table-row .table-col:nth-child(odd),
.single-product-item.with-html-mode .table-main th:nth-child(odd),
.single-product-item.with-html-mode .table-main td:nth-child(odd),
.single-product-item.with-html-mode .table-main .table-row .table-col:nth-child(odd),
.product-characteristic table th:nth-child(odd),
.product-characteristic table td:nth-child(odd),
.product-characteristic table .table-row .table-col:nth-child(odd),
.product-characteristic .table-main th:nth-child(odd),
.product-characteristic .table-main td:nth-child(odd),
.product-characteristic .table-main .table-row .table-col:nth-child(odd) {
  font-weight: bold;
}

.single-product-item.without-link table th:nth-child(even), .single-product-item.without-link table td:nth-child(even), .single-product-item.without-link table .table-row .table-col:nth-child(even),
.single-product-item.without-link .table-main th:nth-child(even),
.single-product-item.without-link .table-main td:nth-child(even),
.single-product-item.without-link .table-main .table-row .table-col:nth-child(even),
.single-product-item.with-html-mode table th:nth-child(even),
.single-product-item.with-html-mode table td:nth-child(even),
.single-product-item.with-html-mode table .table-row .table-col:nth-child(even),
.single-product-item.with-html-mode .table-main th:nth-child(even),
.single-product-item.with-html-mode .table-main td:nth-child(even),
.single-product-item.with-html-mode .table-main .table-row .table-col:nth-child(even),
.product-characteristic table th:nth-child(even),
.product-characteristic table td:nth-child(even),
.product-characteristic table .table-row .table-col:nth-child(even),
.product-characteristic .table-main th:nth-child(even),
.product-characteristic .table-main td:nth-child(even),
.product-characteristic .table-main .table-row .table-col:nth-child(even) {
  font-family: "DINPro-Regular";
}

.single-product-item.without-link table th, .single-product-item.without-link table td, .single-product-item.without-link table .table-col,
.single-product-item.without-link .table-main th,
.single-product-item.without-link .table-main td,
.single-product-item.without-link .table-main .table-col,
.single-product-item.with-html-mode table th,
.single-product-item.with-html-mode table td,
.single-product-item.with-html-mode table .table-col,
.single-product-item.with-html-mode .table-main th,
.single-product-item.with-html-mode .table-main td,
.single-product-item.with-html-mode .table-main .table-col,
.product-characteristic table th,
.product-characteristic table td,
.product-characteristic table .table-col,
.product-characteristic .table-main th,
.product-characteristic .table-main td,
.product-characteristic .table-main .table-col {
  text-align: center;
}

.single-product-item.without-link table th p, .single-product-item.without-link table td p, .single-product-item.without-link table .table-col p,
.single-product-item.without-link .table-main th p,
.single-product-item.without-link .table-main td p,
.single-product-item.without-link .table-main .table-col p,
.single-product-item.with-html-mode table th p,
.single-product-item.with-html-mode table td p,
.single-product-item.with-html-mode table .table-col p,
.single-product-item.with-html-mode .table-main th p,
.single-product-item.with-html-mode .table-main td p,
.single-product-item.with-html-mode .table-main .table-col p,
.product-characteristic table th p,
.product-characteristic table td p,
.product-characteristic table .table-col p,
.product-characteristic .table-main th p,
.product-characteristic .table-main td p,
.product-characteristic .table-main .table-col p {
  margin-bottom: 0 !important;
}

.single-product-item.without-link table .table-row .table-col,
.single-product-item.without-link .table-main .table-row .table-col,
.single-product-item.with-html-mode table .table-row .table-col,
.single-product-item.with-html-mode .table-main .table-row .table-col,
.product-characteristic table .table-row .table-col,
.product-characteristic .table-main .table-row .table-col {
  width: 50%;
}

@media screen and (min-width: 768px) {
  .single-product-item.without-link table th, .single-product-item.without-link table td, .single-product-item.without-link table .table-col, .single-product-item.with-html-mode table th, .single-product-item.with-html-mode table td, .single-product-item.with-html-mode table .table-col {
    padding: 8px;
  }
}

@media screen and (min-width: 768px) {
  .single-product-item.without-link .site-button-wrap {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .single-product-row {
    flex-direction: column;
  }
}

.product-extra-info .site-button,
.button-focused {
  width: 100%;
  max-height: 45px;
  height: 45px;
  background: #F33A20;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
  max-width: 150px;
  color: #fff;
}

.product-extra-info .site-button p,
.button-focused p {
  font-family: "DINPro-Medium";
  font-size: 13px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.product-info-title {
  font-family: "DINPro-Medium";
  font-size: 24px;
  line-height: 26px;
  color: #39434E;
}

.product-info-text {
  font-size: 14px;
  line-height: 18px;
  font-family: "DINPro-Regular";
  color: #39434E;
}

.product-cut {
  text-align: center;
  flex-direction: column;
}

.product-cut:not(:last-child) {
  margin-right: 30px;
}

@media (min-width: 992px) {
  .product-cut:not(.single) {
    min-width: 100px;
  }
}

@media screen and (max-width: 991px) {
  .product-cut:not(.single) {
    min-width: 75px;
  }
}

.product-cut:not(.single) .product-value {
  max-width: 96px;
}

@media (min-width: 992px) {
  .product-cut.single {
    min-width: 120px;
  }
  .product-cut.single p {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .product-cut.single {
    min-width: 100px;
  }
}

.product-cut p {
  display: block;
  margin: 0 0 3px 0;
}

.product-subtitle {
  padding-bottom: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #737373;
  font-family: "DINPro-Medium";
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
}

.product-value {
  font-size: 14px;
  line-height: 18px;
  font-family: "DINPro-Medium";
  color: #39434E;
  margin-bottom: 50px;
}

.current-color {
  background: #FFFFFF;
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.24), 0px 25px 55px rgba(0, 0, 0, 0.19);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, 0);
}

.current-color p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  font-family: "DINPro-Medium";
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #828282;
}

.color span {
  margin-left: 5px;
  color: #39434E;
}

.breadcrumbs,
.kama_breadcrumbs {
  margin: 20px 0 10px 0;
  flex-wrap: wrap;
  font-size: 12px;
}

.breadcrumbs > span:not(.kb_sep),
.kama_breadcrumbs > span:not(.kb_sep) {
  margin: 0 5px;
}

.breadcrumbs > span:not(.kb_sep):first-child,
.kama_breadcrumbs > span:not(.kb_sep):first-child {
  margin-left: 0;
}

.breadcrumbs span,
.kama_breadcrumbs span {
  line-height: 15px;
}

.breadcrumbs span:first-child span,
.kama_breadcrumbs span:first-child span {
  margin-left: 0;
}

.breadcrumbs a,
.kama_breadcrumbs a {
  font-family: "DINPro-Regular";
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #000000;
}

.product-image {
  width: 100%;
  height: 100%;
  background: #fff;
  background-size: cover;
  border: 1px solid #f5f5f5;
  overflow: hidden;
}

.product-image img {
  margin: 0 auto;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 992px) {
  .product-image {
    background: #fff;
  }
  .product-image.with-coverings .image-thumb img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .product-image:not(.with-coverings) img {
    max-width: 100%;
    margin: 0 auto;
    height: auto !important;
  }
  .product-image .image-wrap a {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 991px) {
  .product-image .image-thumb img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .product-image .image-wrap img {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .product-image {
    max-height: 250px;
    margin: 0 auto;
  }
  .product-image .image-thumb a {
    padding: 20px 0;
  }
  .product-image .image-thumb img:not(.img-fluid) {
    max-height: 200px;
  }
}

@media screen and (min-width: 768px) {
  .product-image {
    position: absolute;
  }
}

.product-image-link {
  padding: 1px 7px;
  background: #fff;
  color: #6e6e6e;
  margin-top: 0;
  transition: all .2s linear;
  font-family: "DINPro-Regular";
  font-size: 12px;
  z-index: 2;
}

.product-image .image-wrap a {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.min-image {
  cursor: pointer;
  margin-right: 5px;
}

@media screen and (min-width: 992px) {
  .min-image {
    padding: 1px;
  }
}

.min-image img {
  border: 2px solid #fff;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
}

@media screen and (max-width: 991px) {
  .min-image img {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }
}

@media screen and (max-width: 767px) {
  .min-image img {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-width: 1px;
  }
}

.slick-list,
.slider-for,
.slick-track,
.gallery-carousel {
  height: 100%;
}

.slick-list {
  width: 100%;
}

.slick-list a {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
}

.slick-slide {
  outline: none;
  cursor: pointer;
}

.single-product-item .slick-slide:not(.min-image):not(.image-wrap):not(.gallery-item).slick-current.slick-active {
  box-shadow: inset 0 0 0 2px #F33A20;
}

.single-product-item .slick-slide.min-image.slick-current.slick-active img {
  border-color: #F33A20;
}

.slider-nav {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 50%;
  margin-left: 25%;
  z-index: 2;
}

.slider-nav .slick-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 32px;
}

.slider-nav .slick-slide img {
  max-width: 100%;
}

.slider-nav .slick-slide img.product-slider-image {
  max-width: calc(100% - 4px);
}

@media screen and (max-width: 991px) {
  .slider-nav .slick-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.slider-for,
.gallery-carousel {
  background: #fff;
}

.slider-for .slick-prev,
.slider-for .slick-next,
.gallery-carousel .slick-prev,
.gallery-carousel .slick-next {
  transition: all 0.3s;
  z-index: 2;
  height: 100%;
  width: 100px;
}

@media screen and (min-width: 992px) {
  .slider-for .slick-prev,
  .slider-for .slick-next,
  .gallery-carousel .slick-prev,
  .gallery-carousel .slick-next {
    opacity: 0;
  }
}

.slider-for .slick-prev,
.gallery-carousel .slick-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.325) 0%, rgba(0, 0, 0, 0) 100%);
}

.slider-for .slick-prev::before,
.gallery-carousel .slick-prev::before {
  transform: scale(1.5);
}

.slider-for .slick-next,
.gallery-carousel .slick-next {
  right: 0;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.325) 0%, rgba(0, 0, 0, 0) 100%);
}

.slider-for .slick-next::before,
.gallery-carousel .slick-next::before {
  transform: scale(1.5);
}

.slider-for:hover .slick-next,
.slider-for:hover .slick-prev,
.gallery-carousel:hover .slick-next,
.gallery-carousel:hover .slick-prev {
  opacity: 1;
  z-index: 1;
}

.slick-prev::before,
.slick-next::before {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 1;
}

.slick-prev::before {
  background-image: url(../img/icons/prev.svg);
}

.slick-next::before {
  background-image: url(../img/icons/next.svg);
}

.products-slider-list .slick-prev::before {
  background-image: url(../img/icons/prev_colored.svg);
}

.products-slider-list .slick-next::before {
  background-image: url(../img/icons/next_colored.svg);
}

@media screen and (max-width: 991px) {
  .products-slider-list .slick-prev,
  .products-slider-list .slick-next {
    width: 30px;
    height: 100%;
    z-index: 10;
  }
}

@media screen and (max-width: 991px) {
  .products-slider-list .slick-prev {
    left: 10px;
  }
}

@media screen and (max-width: 991px) {
  .products-slider-list .slick-next {
    right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .product-extra-wrap {
    justify-content: center;
  }
}

.site-button-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .site-button-wrap {
    justify-content: center;
    margin-top: 10px;
    width: 100% !important;
  }
}

.parametrs {
  margin-bottom: 100px;
}

.parametr-collapse .btn-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "DINPro-Medium";
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  color: #39434E;
  text-decoration: none !important;
}

.parametr-collapse .btn-link.collapsed:after {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../img/24x24/arrowdownBIG.svg") no-repeat;
  transition: all .3s;
}

.parametr-collapse .btn-link:not(.collapsed):after {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../img/24x24/arrowdownBIG.svg") no-repeat;
  transition: all .3s;
  transform: rotate(180deg);
}

.card-headerf {
  background: #F5F5F5;
  border: none;
  border-radius: 0;
  margin-bottom: 5px;
}

.card-headerf:first-child {
  border-radius: 0;
}

.card {
  border: none;
  border-radius: 0;
}

.card-body {
  font-size: 14px;
  line-height: 18px;
  font-family: "DINPro-Regular";
  color: #39434E;
}

.brand-image-wrapper {
  display: block;
  padding: 20px 20px 10px 20px;
  max-width: 220px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  color: #000;
  outline: none;
  transition: color .3s ease;
}

.link:active, .link:focus, .link:hover {
  outline: none;
  text-decoration: none;
}

.link:hover {
  color: #F33A20;
}

.link__focus {
  color: #F33A20;
}

.link__focus:hover {
  color: #000;
  text-decoration: underline;
}

table {
  border-collapse: collapse !important;
  border: none !important;
  margin-bottom: 30px;
}

table tr:nth-child(odd) {
  background: #f5f5f5;
}

table th, table td {
  border: none !important;
  padding: 18px 30px;
}

@media screen and (max-width: 991px) {
  table th, table td {
    padding: 10px;
  }
}

.img-response {
  max-width: 100%;
  height: auto;
}

.category-brand-item {
  transition: all .35s;
  margin: 0 auto;
  border-bottom: 3px solid #333;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;
}

.category-brand-item:hover {
  border-color: #F33A20;
}

.category-brand-item img {
  display: block;
  margin: 0 auto;
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
}

.category-brand-item .category-brand-content {
  flex-direction: column;
}

@media (max-width: 991px) {
  .category-brand-item .category-brand-parent {
    padding-bottom: 1rem;
  }
  .category-brand-item .category-brand-description {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .category-brand-item .category-brand-parent {
    padding-bottom: .5rem;
  }
}

.category-brand-item .category-brand-inner {
  display: flex;
  height: inherit;
  align-items: center;
}

@media (max-width: 991px) {
  .category-brand-item .category-brand-inner {
    flex-direction: column;
    align-items: center;
  }
}

.category-brand-item.brand .category-brand-inner-img-wrap {
  min-width: 374px;
  max-width: 374px;
  height: 117px;
}

@media screen and (max-width: 991px) {
  .category-brand-item.brand .category-brand-inner-img-wrap {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .category-brand-item.brand {
    max-width: 100%;
    height: 144px;
    overflow: hidden;
  }
}

.category-brand-item:not(.brand) .category-brand-inner-img-wrap {
  display: flex;
  align-items: center;
  width: 374px;
  min-width: 374px;
  height: 190px;
}

.category-brand-item:not(.brand) .category-brand-inner-img-wrap img {
  max-width: 100%;
  height: auto;
}

.category-brand-item.big-font .category-brand-parent,
.category-brand-item.big-font .category-brand-description {
  font-size: 16px;
}

.category-brand-item.big-font .category-brand-parent p,
.category-brand-item.big-font .category-brand-description p {
  font-size: 16px;
  line-height: 1.3;
}

.category-brand-item .category-brand-inner-img-wrap {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .category-brand-item .category-brand-inner-img-wrap {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .category-brand-item .category-brand-cat {
    display: block;
  }
}

.category-brand-parent {
  transition: all .35s;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}

.category-brand-parent-link {
  color: #CC1900;
}

.category-brand-parent-link::before {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  content: '';
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1100px) {
  .category-brand-parent.font-large {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .category-brand-parent {
    text-align: center;
  }
}

.category-brand-description {
  font-size: 15px;
}

.category-brand-description p {
  font-size: 15px;
}

@media (max-width: 1199px) {
  .category-brand-description,
  .category-brand-description p {
    font-size: 14px;
    line-height: 1.35;
  }
}

@media (max-width: 991px) {
  .products-slider-similar .slick-dots {
    display: none !important;
  }
}

.products-slider-list__big .products-slider-img-wrap {
  height: 250px;
}

.products-slider-list__big .products-slider-img {
  height: 100%;
}

.products-slider-list__big .products-slider-name {
  display: block;
  padding: 10px 5px;
  font-size: 18px;
}

.products-slider-item {
  width: 268px;
  flex-basis: 268px;
  background: #eaeaea;
  transition: .3s;
  overflow: hidden;
  margin: 0 5px;
  box-sizing: border-box;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.products-slider-item:hover {
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 991px) {
  .products-slider-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media screen and (max-width: 460px) {
  .products-slider-item {
    flex-grow: 1;
    flex-basis: 100%;
  }
}

.products-slider-link {
  color: #CC1900;
  text-align: center;
  box-sizing: border-box;
}

.products-slider-img-wrap {
  background: #fff;
  height: 140px;
  overflow: hidden;
}

.products-slider-img {
  max-height: 100%;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.products-slider-name {
  line-height: 1.1;
  font-size: 14px;
}

.expanded-parent .product-expanded {
  display: none;
}

@media screen and (min-width: 992px) {
  .expanded-parent {
    display: flex;
    flex-direction: column;
    overflow: unset;
  }
  .expanded-parent.expanded {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .expanded-parent.expanded::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(transparent, #fff);
    z-index: 2;
  }
  .expanded-parent .product-expanded {
    padding: 3px 0;
    color: #F33A20;
    font-size: 14px;
    z-index: 3;
  }
  .expanded-parent .product-expanded:hover {
    text-decoration: underline;
  }
}

.focus_circle {
  width: 84px;
  height: 84px;
  top: 0;
  right: 0;
}

.focus_svg {
  width: 100%;
  height: 100%;
  fill: #F33A20;
}

.focus_text {
  max-width: 70px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  line-height: 1;
  font-size: 14px;
  user-select: none;
}

.focus_text .big {
  font-size: 22px;
  font-weight: bold;
}

/*-------------------------------------PRODUCT------------------------------------- */
.product-values {
  margin-bottom: 20px;
}

.table-main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-main .table-row {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .table-main .table-row:nth-child(odd) .table-col {
    background-color: #F5F5F5;
  }
}

.table-main .table-row .table-col {
  position: relative;
  width: 25%;
  padding: 20px 7px 20px 30px;
  line-height: 21px;
  font-family: "DINPro-Regular";
  font-size: 15px;
}

.table-main .table-row .table-col:nth-child(odd) {
  width: 28%;
}

.table-main .table-row .table-col:nth-child(even) {
  width: 22%;
}

.table-main .table-row .table-col.single {
  width: 100%;
  font-family: "DINPro-Medium";
}

@media screen and (max-width: 767px) {
  .table-main .table-row .table-col.filled {
    background: #F5F5F5;
  }
}

@media screen and (max-width: 991px) {
  .table-main .table-row .table-col {
    padding: 15px 7px 15px 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .table-main .table-row .table-col {
    padding: 10px 5px;
    font-size: 13px;
    width: 50%;
  }
}

.table-main .table-row .table-col:nth-child(even) {
  font-family: "DINPro-Medium";
  text-align: center;
}

@media screen and (min-width: 768px) {
  .table-main .table-row .table-col::before {
    position: absolute;
    top: 0;
    width: 7px;
    height: 100%;
    background: #fff;
  }
}

@media screen and (max-width: 767px) {
  .table-main .table-row .table-col:nth-child(odd) {
    width: 50%;
  }
  .table-main .table-row .table-col:nth-child(even) {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .table-main .table-row .table-col:nth-child(4n-1)::before {
    content: '';
    right: 100%;
  }
}

@media screen and (min-width: 768px) {
  .table-main .table-row .table-col:nth-child(4n-2)::before {
    content: '';
    left: 100%;
  }
}

.product-title,
.product-title-xs,
.single-product h2,
.single-product h3 {
  font-family: "DINPro-Medium";
  font-weight: 600;
  line-height: 1;
  color: #39434E;
  margin: 25px 0 15px 0;
}

.product-title {
  font-size: 28px !important;
}

.single-product h1 {
  font-size: 32px !important;
}

.single-product h2, .single-product h3 {
  font-size: 24px !important;
}

.single-product h4 {
  font-size: 20px !important;
}

.product-title-xs {
  font-size: 20px;
}

.single-product-wrapper.object-fit-cover img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .single-product-wrapper {
    max-height: 300px;
  }
}

@media screen and (min-width: 992px) {
  .product-gamma {
    padding-left: 20px;
  }
}

.product-gamma-wrap {
  display: flex;
}

.tab-shortcode .product-gamma-wrap,
.tab-shortcode .product-gammas {
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
}

.product-gamma-wrap,
.product-gammas {
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .product-gamma-wrap,
  .product-gammas {
    justify-content: center;
  }
}

.product-gamma-wrap-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all .35s;
}

@media screen and (max-width: 991px) {
  .product-gamma-wrap-item:not(.fancybox-color) {
    width: 96px;
    height: 86px;
  }
}

.product-gamma-wrap-item.texture.covering-button {
  width: auto;
  margin-right: 20px;
}

.product-gamma-wrap-item.texture.single {
  height: auto;
}

.product-gamma-wrap-item.texture.single.active p.btn-main, .product-gamma-wrap-item.texture.single.active b.btn-main {
  background: #CC1900;
}

.product-gamma-wrap-item.texture.single.active-palette a, .product-gamma-wrap-item.texture.single.active a {
  box-shadow: 0 0 0 4px #F33A20;
}

.product-gamma-wrap-item.texture.single a {
  box-shadow: 0 0 0 4px #fff;
}

.product-gamma-wrap-item.texture.single .btn-main {
  padding: 10px;
  font-size: 16px !important;
}

.product-gamma-wrap-item.texture:not(.single) {
  border: 4px solid #fff;
}

.product-gamma-wrap-item.texture:not(.single).active {
  border-color: #F33A20;
}

.product-gamma-wrap-item.texture p, .product-gamma-wrap-item.texture b {
  line-height: 1;
  margin-top: 0;
  font-size: 12px;
}

.product-gamma-wrap-item:not(.texture) .hex {
  border-radius: 50%;
  overflow: hidden;
}

.product-gamma-wrap-item img {
  margin-bottom: 0 !important;
  width: 76px !important;
  height: 76px !important;
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  .product-gamma-wrap-item img {
    width: 50px;
    height: 50px;
  }
}

.product-gamma-wrap-item p {
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  color: #000000;
}

.product-gamma-wrap-item .hex {
  box-shadow: #797979 2px 2px 7px;
  min-width: 48px;
  min-height: 48px;
}

.single-product {
  margin-top: 1rem;
}

.single-product .product-gamma-wrap-item {
  margin-bottom: 10px;
}

.single-product:not(.without-linked-image) .product-gamma-wrap-item {
  margin-right: 10px;
  width: 106px;
  height: 106px;
}

.single-product.without-linked-image .product-gamma-wrap-item:not(:last-child) {
  margin-right: 25px;
}

.single-product.product_type_single_palette.coverings_title:not(.coverings_image) .product-gamma-wrap:not(.product-gamma-wrap-palette) .product-gamma-wrap-item {
  width: auto;
  height: auto;
  margin-bottom: 0;
}

.single-product .description-text img {
  max-width: 100%;
  height: auto;
}

.big-title {
  font-size: 2em;
  line-height: 43px;
  color: #39434E;
  font-family: "DINPro-Bold";
  margin-bottom: 15px;
}

.color-gamma {
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  font-family: "DINPro-Regular";
}

@media screen and (max-width: 991px) {
  .color-gamma {
    margin-bottom: 15px;
  }
}

.gallery-carousel {
  width: 100%;
  margin-bottom: 50px !important;
}

.gallery-item {
  display: flex !important;
  width: 100%;
}

.gallery-item a {
  height: auto;
  min-height: 300px;
  background-size: cover;
  outline: none;
  max-height: 200px;
}

.gallery-item a img {
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-bottom: 5px;
}

.gallery-carousel .slick-dots {
  bottom: -30px;
}

.gallery-carousel .slick-dots li button {
  background: #39434E;
  width: 8px;
  height: 8px;
  border-radius: 25px;
}

.gallery-carousel .slick-dots li button::before {
  content: '';
}

.gallery-carousel .slick-dots .slick-active button {
  background: #F33A20;
  width: 12px;
  height: 12px;
  border-radius: 25px;
}

.narezka-wrap {
  background: #f7f7f7;
}

.narezka-title {
  font-size: 12px;
  margin: 0 auto;
}

.narezka-image {
  max-width: 134px;
  height: 80px;
}

.narezka-image img {
  max-height: 100%;
}

.fancybox-caption__body {
  display: none !important;
}

table th, table td {
  text-align: center;
}

.product-template .fancybox-content {
  background: #fff;
}

.object-fit-cover, ~ img {
  object-fit: cover;
}

@media screen and (min-width: 992px) {
  .desktop-max-height-280 {
    max-height: 280px;
  }
}

.product-thumbnail-box .object-fit-cover {
  min-width: 440px;
}

.product-thumbnail-box .product-thumbnail {
  max-height: 100%;
  width: auto;
}

.single-blog-post *:not(.coverings_image) img {
  display: block;
  margin: 0 auto 1rem auto;
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .single-blog-post *:not(.coverings_image) img.left {
    float: left;
    margin-right: 1rem;
  }
  .single-blog-post *:not(.coverings_image) img.right {
    float: right;
    margin-left: 1rem;
  }
}

.single_page h1,
.single-product .description-text h1,
.single-addition-content h1 {
  margin-bottom: 1.5rem;
  color: #39434E;
}

.single_page h2, .single_page h3, .single_page h4, .single_page h5, .single_page a:not(.page-numbers):not(.site-button),
.single-product .description-text h2,
.single-product .description-text h3,
.single-product .description-text h4,
.single-product .description-text h5,
.single-product .description-text a:not(.page-numbers):not(.site-button),
.single-addition-content h2,
.single-addition-content h3,
.single-addition-content h4,
.single-addition-content h5,
.single-addition-content a:not(.page-numbers):not(.site-button) {
  margin-bottom: 1rem;
  color: #39434E;
  font-family: "DINPro-Medium";
}

.single_page h1,
.single-product .description-text h1,
.single-addition-content h1 {
  font-family: "DINPro-Bold";
}

.single_page h6,
.single-product .description-text h6,
.single-addition-content h6 {
  font-family: "DINPro-Bold";
}

.single_page .focused-link,
.single-product .description-text .focused-link,
.single-addition-content .focused-link {
  font-family: "DINPro-Regular";
  text-decoration: underline;
  color: #F33A20;
  text-transform: uppercase;
}

.single_page section,
.single-product .description-text section,
.single-addition-content section {
  margin-bottom: 3rem;
}

.single_page img,
.single-product .description-text img,
.single-addition-content img {
  max-width: 100%;
  height: auto;
}

.single_page img.aligncenter, .single_page img.alignleft, .single_page img.alignright,
.single-product .description-text img.aligncenter,
.single-product .description-text img.alignleft,
.single-product .description-text img.alignright,
.single-addition-content img.aligncenter,
.single-addition-content img.alignleft,
.single-addition-content img.alignright {
  display: block;
}

.single_page img.aligncenter,
.single-product .description-text img.aligncenter,
.single-addition-content img.aligncenter {
  margin: 0 auto;
}

.single_page img.alignleft,
.single-product .description-text img.alignleft,
.single-addition-content img.alignleft {
  margin-right: auto;
}

.single_page img.alignright,
.single-product .description-text img.alignright,
.single-addition-content img.alignright {
  margin-left: auto;
}

.single_page blockquote,
.single-product .description-text blockquote,
.single-addition-content blockquote {
  display: flex;
  align-items: center;
  background: #F5F5F5;
  padding: 2rem;
  margin-bottom: 3rem;
}

.single_page blockquote img,
.single-product .description-text blockquote img,
.single-addition-content blockquote img {
  margin-right: 3.750rem;
  max-width: 60px;
}

.single_page blockquote::before,
.single-product .description-text blockquote::before,
.single-addition-content blockquote::before {
  content: '';
  margin-right: 2rem;
  display: block;
  width: 80px;
  height: 80px;
  background: url("../../dist/img/icons/important.svg") center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .single_page blockquote,
  .single-product .description-text blockquote,
  .single-addition-content blockquote {
    flex-direction: column;
  }
  .single_page blockquote::before,
  .single-product .description-text blockquote::before,
  .single-addition-content blockquote::before {
    margin-left: auto;
    width: 34px;
    height: 34px;
  }
}

.single_page > p,
.single-product .description-text > p,
.single-addition-content > p {
  color: #39434E;
  font-size: 1em;
  margin-bottom: 1rem;
  font-family: "DINPro-Regular";
}

.single_page > ul:not(.slick-dots) a,
.single_page > ol:not(.slick-dots) a,
.single-product .description-text > ul:not(.slick-dots) a,
.single-product .description-text > ol:not(.slick-dots) a,
.single-addition-content > ul:not(.slick-dots) a,
.single-addition-content > ol:not(.slick-dots) a {
  color: #F33A20;
  text-decoration: underline;
  font-family: "DINPro-Medium";
}

.single_page > ul:not(.slick-dots) a:hover,
.single_page > ol:not(.slick-dots) a:hover,
.single-product .description-text > ul:not(.slick-dots) a:hover,
.single-product .description-text > ol:not(.slick-dots) a:hover,
.single-addition-content > ul:not(.slick-dots) a:hover,
.single-addition-content > ol:not(.slick-dots) a:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .single_page > ul:not(.slick-dots),
  .single_page > ol:not(.slick-dots),
  .single-product .description-text > ul:not(.slick-dots),
  .single-product .description-text > ol:not(.slick-dots),
  .single-addition-content > ul:not(.slick-dots),
  .single-addition-content > ol:not(.slick-dots) {
    font-size: 12px;
  }
}

.single_page ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas),
.single-product .description-text ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas),
.single-addition-content ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) {
  margin-bottom: 2.500rem;
  padding-left: 0;
}

.single_page ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li,
.single-product .description-text ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li,
.single-addition-content ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li {
  color: #39434E;
  font-family: "DINPro-Regular";
  font-size: 1em;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-align: left;
}

.single_page ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li > *,
.single-product .description-text ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li > *,
.single-addition-content ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li > * {
  display: contents;
}

.single_page ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li:before,
.single-product .description-text ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li:before,
.single-addition-content ul:not(.slick-dots):not(.tabs__list):not(.tabs__content):not(.product-gamma-wrap):not(.product-gammas) li:before {
  content: '';
  display: inline-block;
  background: url("../img/icons/tick.svg") no-repeat;
  background-position: center;
  background-size: contain;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-right: 1rem;
}

.single_page ol:not(.slick-dots),
.single-product .description-text ol:not(.slick-dots),
.single-addition-content ol:not(.slick-dots) {
  padding: 0;
  list-style-type: none;
  counter-reset: item;
  margin-bottom: 3rem;
}

.single_page ol:not(.slick-dots) li,
.single-product .description-text ol:not(.slick-dots) li,
.single-addition-content ol:not(.slick-dots) li {
  color: #39434E;
  font-family: "DINPro-Regular";
  font-size: 1em;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-align: left;
}

.single_page ol:not(.slick-dots) li:before,
.single-product .description-text ol:not(.slick-dots) li:before,
.single-addition-content ol:not(.slick-dots) li:before {
  content: counter(item) ".";
  counter-increment: item;
  background: none;
  font-size: 1em;
  font-family: "DINPro-Bold";
  color: #F33A20;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.image-holder {
  width: 130px;
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.image-holder.chestno {
  background-image: url("../../dist/img/icons/3.svg");
}

.image-holder.ekonomno {
  background-image: url("../../dist/img/icons/7.svg");
}

.image-holder.vovremya {
  background-image: url("../../dist/img/icons/21.svg");
}

.image-holder.proffessionalno {
  background-image: url("../../dist/img/icons/23.svg");
}

.image-holder.vygodno {
  background-image: url("../../dist/img/icons/22.svg");
}

.image-holder.nadezhno {
  background-image: url("../../dist/img/icons/9.svg");
}

.iframe-video {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.iframe-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wp-video {
  width: 100% !important;
  margin: 0 auto;
}

#btnMobile {
  display: none;
  right: 57px;
  width: 38px;
  cursor: pointer;
  z-index: 1002;
}

.burger {
  position: relative;
  height: 26px;
  cursor: pointer;
}

.burger i, .burger::after, .burger::before {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #F33A20;
  content: '';
}

.burger i {
  top: 50%;
  transform: translate(0, -50%);
  transition: all .125s linear 0s;
}

.burger::after {
  bottom: 0;
  transition: background .125s linear 0s,bottom .125s linear .125s,transform .125s linear 0s;
}

.burger::before {
  top: 0;
  transition: background .125s linear 0s,top .125s linear .125s,transform .125s linear 0s;
}

.burger.active i {
  opacity: 0;
  width: 0;
  left: 50%;
}

.burger.active::after {
  bottom: 50%;
  transform: translate(0, 50%) rotate(45deg);
  transition: bottom .125s linear 0s,transform .125s linear .125s;
}

.burger.active::before {
  top: 50%;
  transform: translate(0, -50%) rotate(135deg);
  transition: top .125s linear 0s,transform .125s linear .125s;
}

.burger.green i, .burger.green::after, .burger.green::before {
  background-color: #F33A20;
}

@media screen and (max-width: 991px) {
  #btnMobile {
    display: block;
  }
}

.menu-mobile__wrapper {
  padding-bottom: 75px;
  position: fixed;
  box-shadow: 0 -2px 5px #bababa6b;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  overflow: auto;
}

.menu-mobile__wrapper .lang.location-item > a {
  font-size: 12px;
  font-weight: 600;
  color: #39434E;
}

.menu-mobile__wrapper .lang.location-item > a p {
  align-items: center;
}

.menu-mobile__wrapper .lang .address-pop-up {
  right: -10px;
}

.menu-mobile__menu > .menu-mobile__title {
  background: rgba(229, 229, 229, 0.54);
  border-bottom: 1px solid #F33A20;
}

.menu-mobile__list .menu-mobile__parent:not(.show) {
  display: none;
}

.menu-mobile__item {
  transition: all .7s ease;
  opacity: 1;
  transform: translateX(0%);
}

.menu-mobile__item:not(:last-child) .menu-mobile__title {
  border-bottom: 1px solid #DFDFDF;
}

.menu-mobile__item.hide {
  opacity: 0;
  transform: translateX(-100%);
}

.menu-mobile__title {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "DINPro-Regular";
}

.menu-mobile__title-top {
  line-height: 3.5;
}

.menu-mobile__link {
  color: #39434E;
  padding: 9px 0;
  flex-grow: 1;
  text-decoration: none;
  transition: color .3s ease;
}

.menu-mobile__link:hover {
  color: #F33A20;
}

.menu-mobile__arrow {
  cursor: pointer;
}

.menu-mobile__arrow--in {
  height: 100%;
  padding: 6px 6px 6px 20px;
}

.menu-mobile__arrow--in svg {
  transform: rotate(-90deg);
}

.menu-mobile__arrow--out {
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.18);
  margin-right: 1rem;
  width: 40px;
  height: 40px;
  transform: rotate(90deg);
}

@media screen and (min-width: 992px) {
  body:not(.home) .main-menu-block .site-button::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: 13px;
  }
}

body:not(.home) .main-menu-block .site-button:hover + .categories-menu {
  visibility: visible;
  opacity: 1;
}

body:not(.home) .main-menu-block .site-button:hover + .categories-menu > .categories-wrapper {
  display: flex;
}

.main-menu-block .site-button {
  background: #F33A20;
  height: 65px;
  transition: all .25s ease;
}

.main-menu-block .site-button:hover {
  background: #FF5841;
}

.main-menu-block .site-button img, .main-menu-block .site-button p {
  transition: all .25s ease;
}

.main-menu-block {
  cursor: pointer;
}

.main-menu-block .site-button {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.main-menu-block p {
  font-family: "DINPro-Medium";
  font-size: 1.125em;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.main-menu-block p:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 20px;
  background: url("../img/24x24/down.svg");
}

#menu-affix {
  transition: top .25s ease;
}

#menu-affix.affix {
  top: 2px;
  max-width: 275px;
  width: 100%;
  z-index: 99;
}

#menu-affix.affix .site-button {
  max-height: 34px;
}

#menu-affix.affix .site-button img {
  width: 24px;
  height: 24px;
}

#menu-affix.affix .site-button p {
  font-size: 0.8em;
}

#menu-affix.affix .categories-menu {
  top: 34px !important;
  width: 100%;
}

.categories-menu {
  position: absolute;
  top: 75px;
  left: 0;
  right: auto;
  max-width: 1140px;
  width: 275px;
  transition: background-image .3s ease, opacity .3s ease;
  visibility: hidden;
  margin: 0 auto;
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.24), 0px 17px 50px rgba(0, 0, 0, 0.19);
  background-repeat: no-repeat;
  background-position: 275px center;
  background-size: cover;
  background-color: #fff;
  opacity: 0;
  z-index: 3;
}

.categories-menu.w100 {
  width: calc(100% - 30px);
}

.categories-menu.w33 {
  background-position: 275px center;
}

.categories-menu.show {
  visibility: visible;
  opacity: 1;
}

.categories-menu.show > .categories-wrapper {
  display: flex;
}

.categories-menu:hover {
  visibility: visible;
  opacity: 1;
}

.categories-menu:hover > .categories-wrapper {
  display: flex;
}

.categories-list {
  position: relative;
  min-width: 275px;
}

.categories-list:hover > .categories-wrapper {
  display: flex;
}

.categories-item {
  background: #fff;
}

.categories-item:hover > .categories-link {
  background: #EDEDED;
}

.categories-item--has-child:hover > .categories-item {
  visibility: visible;
  opacity: 1;
}

.categories-item--has-child:hover > .categories-wrapper {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.categories-item--has-child .categories-link {
  position: relative;
}

.categories-item--has-child .categories-link .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.categories-item .categories-wrapper {
  position: absolute;
  top: 0;
  left: 100%;
}

.categories-wrapper.categories-wrapper_lvl-1 {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.categories-wrapper {
  display: none;
  bottom: 0;
  transition: all .3s ease;
}

.categories-wrapper:hover {
  display: flex;
}

.categories-wrapper_lvl-2 .categories-item {
  padding-left: 20px;
}

.categories-link {
  display: block;
  padding: 12px 0 12px 20px;
  text-transform: uppercase;
  font-family: "DINPro-Regular";
  font-size: 12px;
  border-bottom: 1px solid #DFDFDF;
  color: #39434E;
  transition: background-color .3s ease;
}

@-webkit-keyframes rotateLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

body:not(.mobile-on):after, body:not(.mobile-on):before {
  position: fixed;
  -webkit-transition: .4s ease;
  transition: .4s ease;
  opacity: 0;
  z-index: -1;
}

body:not(.mobile-on):before {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

body:not(.mobile-on):after {
  width: 120px;
  height: 120px;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-top: 2px solid #F33A20;
  border-radius: 50%;
}

body.loader:not(.mobile-on):after, body.loader:not(.mobile-on):before {
  opacity: 1;
  z-index: 100;
}

body.loader_rotate:not(.mobile-on):after, body.loader_rotate:not(.mobile-on):before {
  content: "";
}

body.loader_rotate:not(.mobile-on):after {
  -webkit-animation: rotateLoader .7s infinite linear;
  animation: rotateLoader .7s infinite linear;
}

.flex-list,
.flex-list-2,
.flex-list-1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  flex-wrap: wrap;
}

.flex-list a,
.flex-list-2 a,
.flex-list-1 a {
  text-decoration: none;
}

.flex-list p,
.flex-list-2 p,
.flex-list-1 p {
  font-weight: bold;
  font-size: 12pt;
  font-family: Arial, sans-serif;
}

.flex-list img,
.flex-list-2 img,
.flex-list-1 img {
  display: block;
  margin: 0 auto 10px auto;
}

.flex-list div,
.flex-list-2 div,
.flex-list-1 div {
  margin-bottom: 1rem;
}

.flex-list div {
  width: 33%;
}

.flex-list-4 div {
  width: 25%;
}

.flex-list-2 div {
  width: 50%;
}

.flex-list-1 div {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .flex-list div {
    width: 50%;
  }
  .flex-list-4 div, .flex-list-2 div {
    width: 50%;
  }
  .flex-list-1 div {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .flex-list div {
    width: 100%;
  }
  .flex-list-1 div, .flex-list-2 div, .flex-list-4 div {
    width: 100%;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}

.affix {
  position: fixed;
}

.cursor-hand {
  cursor: pointer;
}

.header-grow {
  position: relative !important;
}

html.lock, body.lock {
  overflow: hidden;
}

html,
body {
  height: 100%;
  font-family: "DINPro-Regular";
}

.image-mx-auto img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-responsive,
.img-responsive img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.body__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body__wrapper {
  flex: 1 0 auto;
}

#footer-main {
  flex: 0 0 auto;
}

body, html {
  font-size: 16px;
}

body, nav, ul, li, a {
  margin: 0;
  padding: 0;
}

.section {
  padding-top: 4.375rem;
  padding-bottom: 5.625rem;
}

.section .contacts {
  position: relative;
}

.sub-title {
  font-family: "DINPro-Bold";
  font-size: 2.688em;
  line-height: 43px;
  text-align: center;
  color: #39434E;
  margin-bottom: 30px;
}

.title {
  font-family: "DINPro-Bold";
  font-size: 1.125em;
  line-height: 23px;
  text-align: left;
  color: #39434E;
  margin-bottom: 0;
  text-transform: uppercase;
}

.btn-main {
  padding: 10px 15px;
  display: inline-block;
  background: #F33A20;
  color: #fff !important;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24);
  font-size: 16px !important;
  font-weight: normal;
  transition: all .3s ease;
  user-select: none;
}

.btn-main:hover {
  background: #FF5841;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
}

.white-title {
  color: #fff;
}

body {
  position: relative;
}

body:not(.home) #footer-main {
  margin-top: 3rem;
}

.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 10;
  background-size: cover;
  display: none;
}

.back-to-top-btn.visible {
  display: block;
}

.back-to-top-btn a {
  background: url("../img/icons/circleup.svg") no-repeat;
  width: 100px;
  height: 100px;
  display: block;
  transition: all 0.3s;
}

.hidden {
  display: none;
}

.cursor_default {
  cursor: default !important;
}

button[type="submit"]::after {
  content: '';
  width: 12%;
  height: inherit;
  background: url("../img/icons/send.svg") center no-repeat;
}

.wpcf7-form br {
  display: none;
}

.wpcf7-form input {
  min-height: 54px;
  box-sizing: border-box;
}

.wpcf7-form .wpcf7-form-control-wrap {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.wpcf7-form .wpcf7-response-output {
  margin: 0 !important;
}

.wpcf7-form.invalid {
  position: relative;
}

.wpcf7-form.invalid .wpcf7-not-valid-tip {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 1px;
  line-height: 1;
  font-size: 14px;
}

.wpcf7-form .wpcf7-response-output {
  background: #fff;
  position: absolute;
  font-size: 14px;
}

.wpcf7-form .wpcf7-response-output:not(.wpcf7-mail-sent-ok) {
  border: 1px solid #F33A20;
}

.fancybox-content .fancybox-close-small path {
  fill: #F33A20;
}

.fancybox-content .wpcf7-form {
  padding-bottom: 70px;
}

.fancybox-content .wpcf7-form .wpcf7-form-control-wrap {
  margin-bottom: 25px;
}

.fancybox-content .wpcf7-form.invalid .wpcf7-not-valid-tip {
  top: 100%;
}

.fancybox-content .wpcf7-form.invalid .wpcf7-response-output {
  position: absolute;
}

.page-numbers {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 4px 0 0;
  border: 1px solid #eee;
  line-height: 1;
  text-decoration: none;
  border-radius: 2px;
  font-weight: 600;
}

.page-numbers:not(.current) {
  color: #CC1900;
}

.page-numbers.current,
a.page-numbers:hover {
  background: #f9f9f9;
}

.single-addition-content {
  word-break: break-word;
}

body.admin-bar {
  margin-top: 32px;
}

.text-indent-1 {
  text-indent: 1em;
}

.text-indent-2 {
  text-indent: 1.5em;
}

.text-indent-3 {
  text-indent: 2em;
}

.responsive-video {
  position: relative;
  padding-bottom: 36.25%;
  /* 16:9 */
  height: 0;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.form_fields {
  width: 100%;
}

.form_content {
  padding: 50px;
  background: #eeeeee;
}

@media screen and (max-width: 991px) {
  .form_content {
    padding: 15px 20px;
    margin-top: 15px;
  }
}

@media screen and (min-width: 768px) {
  .form_content {
    padding: 15px 20px;
  }
}

.form_content input, .form_content button {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .form_wrapper .responsive-video {
    padding-bottom: 40%;
  }
}

.form_wrapper > .d-flex .responsive-video iframe {
  width: calc(100% - 15px);
}

@media screen and (min-width: 992px) {
  .form_wrapper > .d-flex.flex-row-reverse .responsive-video iframe {
    left: auto;
    right: 0;
  }
  .form_wrapper > .d-flex:not(.flex-row-reverse) .responsive-video iframe {
    left: 0;
    right: auto;
  }
}

@media screen and (max-width: 991px) {
  .form_wrapper .responsive-video iframe {
    width: 100% !important;
  }
}

.form_inner {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.location-nav-list .location-item.location-all-coast,
.mobile-menu,
.mobile-news {
  display: none;
}

.responsive .slick-prev, .responsive .slick-next {
  display: none !important;
}

.multiple-items .slick-prev, .multiple-items .slick-next {
  display: none !important;
}

.location-nav-list {
  justify-content: space-around;
}

.location-nav-list .location-item {
  max-width: max-content;
}

.header-top {
  background: #fff;
  transition: box-shadow .3s ease;
  z-index: 12;
  top: 0;
}

.header-top.affix {
  box-shadow: 0px 2px 10px #ededed;
}

.modal-inline input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 23px;
  font-family: "DINPro-Regular";
}

.modal-inline input::placeholder {
  font-size: 18px;
  line-height: 23px;
  color: #828282;
  font-family: "DINPro-Regular";
}

.modal-inline input:focus {
  border-color: #F33A20;
}

.modal-inline .sub-title {
  font-family: "DINPro-Medium";
  font-size: 36px;
  line-height: 39px;
  color: #39434E;
  text-align: left;
}

.modal-inline .site-button {
  font-size: 18px;
  line-height: 20px;
  font-family: "DINPro-Regular";
  color: #fff;
}

.modal-inline .site-button img {
  margin-left: 20px;
}

.person-info-wrap {
  position: relative;
}

.person-info-wrap p {
  position: absolute;
  right: 0;
  bottom: 35px;
  margin-bottom: 0;
  font-family: "DINPro-Regular";
  font-size: 10px;
  line-height: 12px;
  color: #828282;
}

#contact-us.modal-inline .call-btn {
  max-width: 185px;
}

.product-value {
  font-size: 13px;
}

.parametr-collapse .btn-link {
  text-align: left;
}

@media (min-width: 1600px) {
  .advantages .top-banner-img {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .items-menu {
    flex-wrap: wrap;
  }
  .location-nav-list .location-item.location-coast {
    display: none;
  }
  .location-nav-list .location-item.location-all-coast {
    display: block;
  }
  .top-block-wrap .top-banner-img {
    transform: translate(-20%, 0);
  }
  .location-nav-list .location-item {
    max-width: max-content;
    display: flex;
    justify-content: space-around;
  }
  .items-menu li p {
    font-size: 0.7em;
  }
  .home .second-main-menu {
    margin: 0 auto 10px auto;
  }
}

@media screen and (max-width: 991px) {
  .header-top {
    margin-bottom: .5rem;
  }
  .lang {
    padding: 0 !important;
  }
  header {
    padding-top: 15px;
  }
  .second-main-menu .header-catalog {
    display: none;
  }
  .top-block-wrap {
    margin-top: 15px;
  }
  .top-block-wrap .container {
    padding-top: 35px;
  }
  .back-call {
    display: none;
  }
  .main-logo {
    width: 225px;
  }
  .location-nav-list .location-item.location-all-coast {
    max-width: max-content;
  }
  .address-pop-up {
    top: 50px;
  }
  .lang .address-pop-up {
    top: 45px;
  }
  .mobile-menu {
    display: flex;
  }
  .location-info {
    margin-bottom: 15px;
  }
  .back-to-top-btn {
    right: 0;
    bottom: 0;
  }
  .breadcrumbs {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .items-menu {
    position: relative;
  }
  .items-menu::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: #fff;
  }
  .items-menu .single-item {
    margin-bottom: 1rem;
  }
  .home .second-main-menu {
    margin-bottom: 0;
  }
  .footer-links {
    display: none;
  }
  #order-call .site-button {
    font-size: 12px;
    line-height: 14px;
    font-family: "DINPro-Regular";
    color: #fff;
    padding: 10px 20px;
  }
  .second-main-menu {
    padding-top: 10px;
  }
  .map {
    display: none;
  }
  .operators {
    border: none;
  }
  .location-button a {
    transform: rotate(90deg);
  }
  .loc-arrow {
    align-items: flex-end !important;
  }
  .contact-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 33px;
    margin-bottom: 20px;
  }
  .contact-item:last-of-type {
    border-bottom: none;
  }
  .about-house-icon {
    display: none;
  }
  .about-wrapper {
    flex-direction: column;
  }
  .about-wrap p {
    text-align: left !important;
  }
  .location-nav .location-item {
    display: none !important;
  }
  .location-nav-list {
    justify-content: flex-end;
    height: 100%;
  }
  .top-block-wrap {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 20px;
  }
  .section {
    padding-top: 20px;
  }
  .top-block-wrap .container {
    padding-top: 0;
  }
  .responsive .slick-slider .slick-track, .responsive .slick-slider .slick-list {
    padding: 0 !important;
    padding-right: 50px !important;
  }
  .multiple-items .slick-slider .slick-track, .multiple-items .slick-slider .slick-list {
    padding: 0 !important;
    padding-right: 50px !important;
  }
  .news-title {
    flex-direction: column;
  }
  .sub-title {
    font-size: 24px;
  }
  .to-all-articles {
    width: 100%;
    justify-content: flex-end;
  }
  .contacts h2 {
    margin-bottom: 24px;
  }
  .mobile-news {
    display: block;
  }
  .license {
    margin-bottom: 20px;
  }
  footer.section {
    padding: 30px 0;
  }
  .product-info {
    padding: 15px;
  }
  .parametr-collapse .btn-link {
    font-size: 13px;
  }
  .card-body, .product-info-text {
    font-size: 12px;
  }
  .product-cut {
    margin-top: 10px;
  }
  .product-title {
    font-size: 24px;
  }
  .product-values table td {
    font-size: 14px;
  }
  .advantages .top-banner-img {
    width: auto !important;
    transform: translate(-25%);
  }
}

@media screen and (min-width: 992px) {
  .location-nav-list {
    align-items: flex-start;
  }
}
