@import '../vars', '../mixins/_mixins.scss', './colorbox';

.category-banner {
  display: block;
  padding-bottom: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &.fat {
    padding-bottom: 33%; } }

.product-info {
  padding: 3%;
  //padding: 50px 100px 55px 120px
  @include b(sm) {
    min-height: 250px; } // @TODO, Strange
  @include b(desktop) {
    min-height: 300px; } }

.product-wrap {
  margin-bottom: 30px; }

.single-product-item {
  background: #F5F5F5;
  margin: 0 auto 10px auto;
  transition: all .3s;
  @include b(desktop) {
    .product-image {
      .image-wrap a {
        height: 300px;
        //height: 100% // temp
        overflow: hidden; }
      &:not(.with-coverings) img {
        max-height: 100%; } } }
  @include b(xxs) {
    max-width: 300px; }
  &:hover {
    box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.24), 0px 25px 55px rgba(0, 0, 0, 0.19);
    background: #fff;
    .product-image-link {
      color: #fff;
      background: $mainColor; } }
  .slider-for {
    @include b(xs) {
      max-height: 250px; } }
  &.without-link,
  &.with-html-mode {
    background: #fff;
    border: 1px solid #e3e3e3;
    .product-info {
      padding: 10px 20px; }
    .product-info-wrapped {
      @include b(xs) {
        display: none; } } } }


.single-product-item.without-link,
.single-product-item.with-html-mode,
.product-characteristic {
  table,
  .table-main {
    width: 100%;
    font-size: 14px;
    th,td, .table-row .table-col {
      line-height: 1.15;
      &:nth-child(odd) {
        font-weight: bold; }
      &:nth-child(even) {
        font-family: $ffDinProRegular; } }
    th,td, .table-col {
      text-align: center;
      p {
        margin-bottom: 0 !important; } }
    .table-row .table-col {
      width: 50%; }
    margin-bottom: 0; } }

.single-product-item {
  &.without-link,
  &.with-html-mode, {
    table, {
      th,td, .table-col {
        @include b(sm) {
          padding: 8px; } } } }
  &.without-link {
    @include b(sm) {
      .site-button-wrap {
        display: none; } } } }

.single-product-row {
  @include b(xs) {
    flex-direction: column; } }

.product-extra-info .site-button,
.button-focused {
  width: 100%;
  max-height: 45px;
  height: 45px;
  background: #F33A20;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
  max-width: 150px;
  color: #fff;
  p {
    font-family: $ffDinProMedium;
    font-size: 13px;
    line-height: 14px;
    text-transform: uppercase;
    color: #FFFFFF; } }

.product-info-title {
  font-family: $ffDinProMedium;
  font-size: 24px;
  line-height: 26px;
  color: #39434E; }

.product-info-text {
  font-size: 14px;
  line-height: 18px;
  font-family: $ffDinProRegular;
  color: #39434E;
  //@include b(desktop)
 }  //  margin-bottom: 50px

.product-cut {
  text-align: center;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 30px; }
  &:not(.single) {
    @include bc((min: 992)) {
      min-width: 100px; }
    @include b(tablet) {
      min-width: 75px; }
    .product-value {
      max-width: 96px; } }
  // Only product-page
  &.single {
    @include bc((min: 992)) {
      min-width: 120px;
      p {
        font-size: 14px; } }
    @include b(tablet) {
      min-width: 100px; } }
  p {
    display: block;
    margin: 0 0 3px 0; } }

.product-subtitle {
  padding-bottom: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #737373;
  font-family: $ffDinProMedium;
  white-space: nowrap;
  border-bottom: 1px solid #ccc; }

.product-value {
  font-size: 14px;
  line-height: 18px;
  font-family: $ffDinProMedium;
  color: #39434E;
  margin-bottom: 50px; }

.current-color {
  background: #FFFFFF;
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.24), 0px 25px 55px rgba(0, 0, 0, 0.19);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, 0);
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-family: $ffDinProMedium;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #828282; } }

.color span {
  margin-left: 5px;
  color: #39434E; }

.breadcrumbs,
.kama_breadcrumbs {
  margin: 20px 0 10px 0;
  flex-wrap: wrap;
  font-size: 12px;
  &>span:not(.kb_sep) {
    margin: 0 5px;
    &:first-child {
      margin-left: 0; } }
  span {
    line-height: 15px;
    &:first-child {
      span {
        margin-left: 0; } } }
  a {
    font-family: $ffDinProRegular;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000; } }

.product-image {
  width: 100%;
  height: 100%;
  background: #fff;
  background-size: cover;
  border: 1px solid #f5f5f5;
  overflow: hidden;
  img {
    //width: 100%
    margin: 0 auto;
    height: 100%;
    object-fit: cover; }

  @include b(desktop) {
    background: #fff;
    &.with-coverings {
      //position: absolute
      .image-thumb {
        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: 100%;
          object-fit: cover; } } }
    &:not(.with-coverings) {
      img {
        max-width: 100%;
        margin: 0 auto;
        height: auto !important; } }

    .image-wrap a {
      display: flex;
      align-items: center; } }
  @include b(tablet) {
    .image-thumb img {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      display: block; }
    .image-wrap img {
      max-width: 100%;
 } }      //height: auto
  @include b(xs) {
    max-height: 250px;
    margin: 0 auto;
    .image-thumb {
      a {
        padding: 20px 0; }
      img:not(.img-fluid) {
        max-height: 200px; } } }
  @include b(sm) {
    position: absolute; }
  &-link {
    padding: 1px 7px;
    background: #fff;
    color: #6e6e6e;
    margin-top: 0;
    transition: all .2s linear;
    font-family: $ffDinProRegular;
    font-size: 12px;
    z-index: 2; }
  .image-wrap {
    a {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } } }

.min-image {
  cursor: pointer;
  margin-right: 5px;
  //box-shadow: inset 0 0 0 2px #fff
  @include b(desktop) {
    padding: 1px; }
  img {
    border: 2px solid #fff;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    @include b(tablet) {
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px; }
    @include b(xs) {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      border-width: 1px; } } }

.slick-list,
.slider-for,
.slick-track,
.gallery-carousel {
  height: 100%; }

.slick-list {
  width: 100%;
  a {
    margin-bottom: 0 !important; } }

.slick-dotted.slick-slider {
  @include b(xs) {
    margin-bottom: 0; } }

// todo need to check all sliders
.slick-slide {
  outline: none;
  cursor: pointer;
  //img
  //  width: 100%
  //  height: 100%
  //  margin: 0 auto
 }  //  z-index: -1

.single-product-item {
  .slick-slide {
    &:not(.min-image):not(.image-wrap):not(.gallery-item).slick-current.slick-active {
      box-shadow: inset 0 0 0 2px $mainColor; }
    &.min-image.slick-current.slick-active {
      img {
        border-color: $mainColor; } } } }

.slider-nav {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 50%;
  margin-left: 25%;
  z-index: 2;
  .slick-slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 32px;
    img {
      max-width: 100%;
      &.product-slider-image {
        max-width: calc(100% - 4px); }
      @include b(tablet) {
        width: 100%;
        //height: 100%
        height: auto;
        object-fit: cover; } } } }

.slider-for,
.gallery-carousel {
  background: #fff;
  .slick-prev,
  .slick-next {
    transition: all 0.3s;
    z-index: 2;
    height: 100%;
    width: 100px;
    @include b(desktop) {
      opacity: 0; } }
  .slick-prev {
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.325) 0%, rgba(0, 0, 0, 0) 100%);
    &::before {
      transform: scale(1.5); } }
  .slick-next {
    right: 0;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.325) 0%, rgba(0, 0, 0, 0) 100%);
    &::before {
      transform: scale(1.5); } }
  &:hover {
    .slick-next,
    .slick-prev {
      opacity: 1;
      z-index: 1; } } }



.slick-prev,
.slick-next {
  &::before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    opacity: 1; } }

.slick-prev::before {
  background-image: url(../img/icons/prev.svg); }

.slick-next::before {
  background-image: url(../img/icons/next.svg); }

.products-slider-list {
  .slick-prev::before {
    background-image: url(../img/icons/prev_colored.svg); }
  .slick-next::before {
    background-image: url(../img/icons/next_colored.svg); }
  .slick-prev,
  .slick-next {
    @include b(tablet) {
      width: 30px;
      height: 100%;
      z-index: 10; } }
  .slick-prev {
    @include b(tablet) {
      left: 10px; } }
  .slick-next {
    @include b(tablet) {
      right: 10px; } } }


.product-extra-wrap {
  @include b(xs) {
    justify-content: center; } }

.site-button-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @include b(xs) {
    justify-content: center;
    margin-top: 10px;
    width: 100% !important; } }

.parametrs {
  margin-bottom: 100px; }

.parametr-collapse .btn-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $ffDinProMedium;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  color: #39434E;
  text-decoration: none !important;
  &.collapsed:after {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('../img/24x24/arrowdownBIG.svg') no-repeat;
    transition: all .3s; }
  &:not(.collapsed):after {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('../img/24x24/arrowdownBIG.svg') no-repeat;
    transition: all .3s;
    transform: rotate(180deg); } }

.card-headerf {
  background: #F5F5F5;
  border: none;
  border-radius: 0;
  margin-bottom: 5px;
  &:first-child {
    border-radius: 0; } }

.card {
  border: none;
  border-radius: 0; }

.card-body {
  font-size: 14px;
  line-height: 18px;
  font-family: $ffDinProRegular;
  color: #39434E; }

.brand-image-wrapper {
  display: block;
  padding: 20px 20px 10px 20px;
  max-width: 220px;
  margin: 0 auto; }


.link {
  cursor: pointer;
  color: #000;
  outline: none;
  transition: color .3s ease;
  &:active,&:focus,&:hover {
    outline: none;
    text-decoration: none; }
  &:hover {
    color: $mainColor; }
  &__focus {
    color: $mainColor;
    &:hover {
      color: #000;
      text-decoration: underline; } } }

table {
  border-collapse: collapse !important;
  border: none !important;
  margin-bottom: 30px;
  tr:nth-child(odd) {
    background: #f5f5f5; }
  th,td {
    border: none !important;
    padding: 18px 30px;
    @include b(tablet) {
      padding: 10px; } } }

//.product-info
//  table
//    margin-bottom: 0
//    th,td
//      padding: 5px
//      font-size: 14px
//      p
//        font-size: 14px
//        margin: 0 !important
//        padding: 0 !important

.img-response {
  max-width: 100%;
  height: auto; }

.category-brand-item {
  transition: all .35s;
  margin: 0 auto;
  border-bottom: 3px solid #333;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;
  &:hover {
    border-color: $mainColor; }
  img {
    display: block;
    margin: 0 auto;
    object-fit: cover;
    width: 100% !important; //@TODO Temp
    height: 100% !important; } //@TODO Temp
  .category-brand-content {
    flex-direction: column;
 }    //align-self: flex-start
  @include bc((max: 991)) {
    .category-brand-parent {
      padding-bottom: 1rem; }
    .category-brand-description {
      display: none !important; } }
  @include b(xs) {
    .category-brand-parent {
      padding-bottom: .5rem; } }
  .category-brand-inner {
    display: flex;
    height: inherit;
    align-items: center;
    @include bc((max: 991)) {
      flex-direction: column;
      align-items: center; } }
  &.brand {
    .category-brand-inner-img-wrap {
      min-width: 374px;
      max-width: 374px;
      height: 117px;
      @include b(tablet) {
        margin: 0 !important; } }
    @include bc((min: 992)) {
      max-width: 100%;
      height: 144px;
      overflow: hidden; } }
  &:not(.brand) {
    .category-brand-inner-img-wrap {
      display: flex;
      align-items: center;
      width: 374px;
      min-width: 374px;
      height: 190px;
      img {
        max-width: 100%;
        height: auto; } } }
  &.big-font {
    .category-brand-parent,
    .category-brand-description {
      font-size: 16px;
      p {
        font-size: 16px;
        line-height: 1.3; } } }

  .category-brand-inner-img-wrap {
    overflow: hidden;
    @include b(xs) {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important; } }
  .category-brand-cat {
    @include b(xs) {
      display: block; } } }

.category-brand-parent {
  transition: all .35s;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  &-link {
    color: $mainColorDark;
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 100%; } }
  &.font-large {
    @include b(md) {
      font-size: 26px; } }
  @include b(xs) {
    text-align: center; } }

.category-brand-description {
  font-size: 15px;
  p {
    font-size: 15px; } }

@include bc((max: 1199)) {
  .category-brand-description,
  .category-brand-description p {
    font-size: 14px;
    line-height: 1.35; } }

$product-width: 268px;

.products-slider {
  &-similar {
    @include bc((max: 991)) {
      .slick-dots {
        display: none !important; } } }

  &-list {
    //overflow: hidden
    &__big {
      .products-slider-img-wrap {
        height: 250px; }
      .products-slider-img {
        height: 100%; }
      .products-slider-name {
        display: block;
        padding: 10px 5px;
        font-size: 18px; } } }
  &-item {
    width: $product-width;
    flex-basis: $product-width;
    background: #eaeaea;
    transition: .3s;
    overflow: hidden;
    margin: 0 5px;
    box-sizing: border-box;
    box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.2);
    &:hover {
      box-shadow: 0 2px 5px 2px rgba(0,0,0,.4); }
    @include b(tablet) {
      width: 50%;
      flex-basis: 50%; }
    @include b(xxs) {
      flex-grow: 1;
      flex-basis: 100%; } }


  &-link {
    color: $mainColorDark;
    text-align: center;
    box-sizing: border-box; }

  &-img-wrap {
    background: #fff;
    height: 140px;
    overflow: hidden; }

  &-img {
    max-height: 100%;
    width: 100%;
    margin: 0 auto;
    object-fit: cover; }

  &-name {
    line-height: 1.1;
    font-size: 14px; } }

.expanded-parent {
  .product-expanded {
    display: none; }
  @include b(desktop) {
    display: flex;
    flex-direction: column;
    overflow: unset;
    &.expanded {
      position: relative;
      overflow: hidden;
      z-index: 1;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(transparent, #fff);
        z-index: 2; } }
    .product-expanded {
      padding: 3px 0;
      color: $mainColor;
      font-size: 14px;
      z-index: 3;
      &:hover {
        text-decoration: underline; } } } }


$focusCircleMaxSize: 84px;

.focus_circle {
  width: $focusCircleMaxSize;
  height: $focusCircleMaxSize;
  top: 0;
  right: 0; }

.focus_svg {
  width: 100%;
  height: 100%;
  fill: $mainColor;
 }  //transform: translateX(25%)

.focus_text {
  max-width: $focusCircleMaxSize - 14px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  line-height: 1;
  font-size: 14px;
  user-select: none;
  .big {
    font-size: 22px;
    font-weight: bold; } }
