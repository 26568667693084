@import '../vars';
/*reviews */

.reviews {
  background: #F5F5F5;
  .name {
    font-family: $ffDinProMedium;
    font-size: 1em;
    line-height: 18px;
    text-align: left;
    color: #39434E;
    margin-bottom: 10px; }
  .profession {
    font-family: $ffDinProRegular;
    font-size: 1em;
    line-height: 18px;
    text-align: left;
    color: #39434E; }
  .item p {
    font-family: $ffDinProRegular;
    font-size: 0.875em;
    line-height: 18px;
    color: #39434E;
    margin-top: 20px; } }

/*slick main page */

.responsive .slick-dots, .multiple-items .slick-dots {
  display: flex;
  background: #DFDFDF;
  height: 4px; }

.responsive .slick-dots li, .multiple-items .slick-dots li {
  flex: 1;
  background: #DFDFDF;
  height: 4px;
  margin: 0; }

.responsive .slick-dots li.slick-active, .multiple-items .slick-dots li.slick-active {
  flex: 1;
  background: #F33A20;
  height: 4px; }
