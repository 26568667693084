@import '../vars', '../mixins/mixins';
/*nav */

.header-row {
  display: flex;
  position: relative;
  .header-col-3 {
    @include b(desktop) {
      min-width: $categoryItemWidth; } }
  .header-grow {
    flex-grow: 1; } }

.header-info {
  margin-left: auto !important; }

.location-nav-list {
  .location-item.first-item-menu li a {
    flex: 1; } }

.location-item {
  &.lang:hover p {
    color: $mainColor !important; }
  &:hover {
    .location-wrap {
      p, span {
        color: $mainColor !important;
        fill: $mainColor; } }
    .nav-list svg {
      path, circle {
        color: $mainColor !important;
        fill: $mainColor; } } } }

.location-nav-list .location-item {
  position: relative;
  float: left;
  border-right: 1px solid #DFDFDF;
  padding-bottom: 15px;
  padding: 0;
  &:not(.lang) {
    a {
      display: flex; } }
  &.lang {
    a {
      display: inline-block; } }
  a {
    padding: 10px 15px;
    color: #39434E; } }

.location-item {
  position: relative; }

.address-pop-up {
  position: absolute;
  z-index: 200;
  top: 60px;
  left: -50%;
  background: #fff;
  width: max-content;
  transition: all 0.2s ease;
  transform: translateY(10%);
  visibility: hidden;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
  opacity: 0;
  &:before {
    content: '';
    width: 50px;
    height: 50px;
    background: #fff;
    position: absolute;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    top: -5px; } }
.lang .address-pop-up:before {
  content: '';
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  z-index: -1;
  left: unset;
  transform: translate(0, 0) rotate(45deg);
  top: -5px;
  right: 7px; }
.address-details {
  padding: 15px;
  max-width: 330px; }

.main-logo {
  //max-width: 100%
  //width: $categoryItemWidth
  max-width: $categoryItemWidth;
  width: 100%;
  transition: all 0.3s ease; }

.lang {
  max-width: 90px;
  padding: 0 15px 15px 15px !important;
  &>a {
    margin-left: 0;
    display: flex;
    justify-content: flex-end; } }

.location-nav-list .nav-list {
  flex-direction: row;
  align-items: normal;
  justify-content: flex-start; }

.nav {
  &.location-nav-list > li.hover > ul {
    top: 50px; }
  li {
    background: #fff; } }

.location-nav-list {
  .nav-list {
    p {
      font-size: 1.125em; }
    span {
      font-family: $ffDinProRegular;
      font-size: 0.625em;
      line-height: 12px;
      color: #828282;
      text-transform: uppercase; } }
  > li.lang > .nav-list p {
    font-family: $ffDinProMedium;
    font-size: 0.6875rem;
    color: #39434E;
    line-height: 12px;
    margin-top: 5px;
    &:after {
      margin-top: -6px; } }
  > li {
    > .nav-list img {
      height: 21px;
      margin-right: 10px; }
    border: none !important; } }

.nav-list {
  p {
    display: flex;
    justify-content: space-between;
    font-family: $ffDinProRegular;
    line-height: 17px; }
  p:after {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('../img/24x24/toolbar/arrowdown.svg') no-repeat; } }

.location-nav {
  .address-details {
    p, span, li, a {
      border-bottom: none;
      padding: 0;
      padding-bottom: 15px;
      font-size: 1.125rem;
      line-height: 23px;
      display: flex; }
    p {
      padding-bottom: 0;
      display: flex;
      line-height: 23px;
      padding-left: 43px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: url('../img/menu/location.svg') no-repeat;
        width: 28px;
        height: 28px;
        left: 15px; } }
    span {
      padding-left: 45px;
      color: #828282;
      font-size: 0.750em;
      background: #fff;
      z-index: 1;
      width: max-content; } }
  > li.hover > .lang-details {
    left: -20px; } }

.lang-details {
  background: #fff;
  z-index: 1;
  width: max-content;
  box-shadow: 0px 25px 55px rgba(0, 0, 0, 0.19);
  li {
    width: 100%;
    background: #fff;
    z-index: 1;
    border-bottom: 1px solid #DFDFDF;
    a {
      border: none !important;
      text-transform: uppercase;
      text-align: left;
      padding: 20px !important;
      font-size: 0.75rem;
      line-height: 13px;
      font-family: $ffDinProMedium;
      color: #39434E; }
    &:last-child {
      border-bottom: none; } } }

.lang-details li a:hover {
  color: $mainColor; }

.location-nav .address-details {
  li {
    padding: 20px 100px 20px 35px;
    width: max-content;
    background: #fff;
    z-index: 1; }
  a {
    &:before {
      content: '';
      width: 28px;
      height: 28px;
      margin-right: 15px;
      display: inline-block; }
    &.life:before {
      background: url('../img/icons/mobile/Life.svg') no-repeat; }
    &.mts:before {
      background: url('../img/icons/mobile/Vector.svg') no-repeat; }
    &.kievstar:before {
      background: url('../img/icons/mobile/kievstar.svg') no-repeat; } } }

.call-btn {
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: $mainColor;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.24);
  border: none;
  text-transform: uppercase;
  min-width: 200px;
  max-height: 45px;
  p {
    font-family: $ffDinProMedium;
    font-size: 0.813em;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF; } }

.items-menu li p {
  font-size: 0.75rem;
  line-height: 13px;
  text-transform: uppercase;
  font-family: $ffDinProMedium;
  padding: 0 22px;
  margin-top: 5px; }

.partner-item {
  transition: all .3s ease;
  border-right: 1px solid #DFDFDF;
  &:hover, &:focus {
    opacity: .6; } }

.site-button {
  transition: all 0.3s ease;
  &:hover {
    background-color: #FF5841; }
  &:focus {
    background-color: $mainColorDark;
    box-shadow: none;
    outline: none; } }

.arrow_down {
  fill: $mainColor; }

.location-item, .items-menu > li {
  svg {
    fill: #39434E; }
  &:hover svg {
    fill: $mainColor; } }

.nav.items-menu > li:active svg {
  fill: $mainColor; }

.items-menu > li > a:focus svg,
.items-menu > li.active > a svg,
.location-item a:focus svg {
  fill: $mainColorDark; }

.items-menu > li:hover p,
.nav.items-menu > li:active p {
  color: $mainColor; }

.items-menu > li > a:focus p,
.items-menu > li.active > a p {
  color: $mainColorDark; }

.location-nav-list > li > .nav-list {
  &:hover {
    svg {
      path, circle {
        fill: $mainColor; } }
    p {
      color: $mainColor; } }
  &:focus {
    svg {
      path, circle {
        fill: $mainColorDark; } }
    p {
      color: $mainColorDark; } } }

.opt {
  transition: all 0.3s ease;
  cursor: pointer;
  div {
    &:hover {
      a, span {
        color: $mainColor !important; } }
    &:focus {
      a, span {
        color: $mainColorDark !important; } } } }

.opt svg {
  margin-right: 20px; }

.single-item {
  position: relative;
  flex: 1;
  a {
    display: flex;
    align-items: normal;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    svg {
      margin: 0 auto; } }
  svg.arrow_down {
    position: absolute;
    display: block;
    top: calc(100% - 7px);
    left: calc(50% - 12px); } }

//.arrow_down
//  margin-top: -5px

.first-item-menu {
  display: none; }

.single-item {
  &:hover .first-item-menu {
    display: flex;
    visibility: visible;
    opacity: 1;
    position: absolute;
    width: max-content;
    left: 0;
    z-index: 10;
    flex-direction: column;
    background: #fff;
    min-width: 100%;
    box-shadow: 0px 25px 55px rgba(0, 0, 0, 0.19); }
  &.active {
    a {
      color: red; } } }

.items-menu .single-item {
  border-right: 1px solid #DFDFDF;
  &:nth-child(1) {
    border-left: none; }
  &:last-child {
    border-right: none;
    border-bottom: none; }
  p {
    color: #39434E; } }

.sub-item-menu {
  display: none; }

.first-item-menu {
  li {
    position: relative;
    font-size: 0.75rem;
    line-height: 13px;
    text-transform: uppercase;
    font-family: $ffDinProMedium;
    &:hover {
      .down_arror {
        transform: translateY(-50%) rotate(180deg); } }
    a {
      position: relative;
      color: #39434E;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 15px 25px 15px 20px;
      border-bottom: 1px solid #DFDFDF;
      font-family: $ffDinProRegular; }

    &:hover {
      background: #F5F5F5; } }
  a {
    text-align: left; }
  li:hover .sub-item-menu {
    display: flex;
    flex-direction: column;
    width: max-content;
    background: #fff;
    min-width: 100%; } }

.down_arror {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  background: url('../img/24x24/toolbar/arrowdown.svg') no-repeat;
  width: 25px;
  height: 25px;
  transition: .2s ease; }

.sub-item-menu li a {
  padding-left: 40px; }

.location-item:hover .address-pop-up {
  opacity: 1;
  transform: translateY(0%);
  visibility: visible; }

.lang .address-pop-up {
  top: 100%;
  width: 114px;
  right: 0;
  left: unset; }

.address {
  display: flex;
  align-items: normal;
  svg {
    margin-right: 15px;
    min-width: 16px; } }

//header
//  width: 100%
//  position: fixed
//  top: 0
//  background: #fff
//  z-index: 20
//  padding-top: 25px
//  padding-bottom: 10px
//  transition: all .3s ease

.second-main-menu {
  @include b(desktop) {
    margin-bottom: 10px; }
  @include b(xs) {
    border-bottom: 1px solid #ececec; } }

.top-block-wrap h1 {
  margin-top: 1.5rem; }

header.scroll {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06), 0px 2px 5px rgba(0, 0, 0, 0.16);
  padding-top: 15px;
  .main-logo {
    width: 160px; } }


.page-template-default {
  table {
    border: 3px double grey !important;
    tr, td {
      border-bottom: 1px solid grey !important;
      td[rowspan] {
        border: 2px dashed grey !important; } } } }
