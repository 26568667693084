@import '../vars';

// Mobile Button
#btnMobile {
  display: none;
  right: 57px;
  width: 38px;
  cursor: pointer;
  z-index: 1002; }

.burger {
  position: relative;
  height: 26px;
  cursor: pointer; }

.burger i,.burger::after,.burger::before {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: $mainColor;
  content: ''; }

.burger i {
  top: 50%;
  transform: translate(0,-50%);
  transition: all .125s linear 0s; }

.burger::after {
  bottom: 0;
  transition: background .125s linear 0s,bottom .125s linear .125s,transform .125s linear 0s; }

.burger::before {
  top: 0;
  transition: background .125s linear 0s,top .125s linear .125s,transform .125s linear 0s; }

.burger.active i {
  opacity: 0;
  width: 0;
  left: 50%; }

.burger.active::after {
  bottom: 50%;
  transform: translate(0,50%) rotate(45deg);
  transition: bottom .125s linear 0s,transform .125s linear .125s; }

.burger.active::before {
  top: 50%;
  transform: translate(0,-50%) rotate(135deg);
  transition: top .125s linear 0s,transform .125s linear .125s; }

.burger.green i,.burger.green::after,.burger.green::before {
  background-color: $mainColor; }

@media screen and (max-width: 991px) {
  #btnMobile {
    display: block;
  }
}