@import '../vars', '../mixins/_mixins';
/*news */

.to-all-articles {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: $ffDinProMedium;
    font-size: 0.813em;
    line-height: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #39434E; } }

.news-item {
  padding: 15px;
  transition: all 0.3s ease;
  &:hover, &:focus {
    box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.24); }
  .image-wrap img {
    @include b(desktop) {
      width: 100%;
      height: 160px; }
    @include b(tablet) {
      max-width: 100%;
      height: auto; }
    @include b(xxs) {
      margin: 0 auto;
      display: block; } }
  .category {
    font-family: $ffDinProRegular;
    font-size: 0.750em;
    line-height: 15px;
    color: #39434E;
    padding-left: 14px;
    border-left: 1px solid #39434E; }
  .article-title {
    display: block;
    font-family: $ffDinProMedium;
    font-size: 1.125em;
    line-height: 1.2;
    text-align: left;
    color: #39434E;
    margin-top: 10px;
    margin-bottom: 0 !important;
    transition: color .3s ease;
    &:hover {
      color: $mainColorDark !important; } }
  p {
    font-family: $ffDinProRegular;
    font-size: 0.875em;
    line-height: 18px;
    color: #39434E;
    margin: 10px 0;
    word-break: break-word; }
  .category-btn {
    border: 1px solid #39434E;
    font-family: $ffDinProMedium;
    font-size: 0.8125rem;
    line-height: 14px;
    color: #39434E;
    padding: 10px 13px 10px 20px;
    text-transform: uppercase; } }
