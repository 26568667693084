@import 'vars';

.page-numbers {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 4px 0 0;
    border: 1px solid #eee;
    line-height: 1;
    text-decoration: none;
    border-radius: 2px;
    font-weight: 600;
    &:not(.current) {
        color: $mainColorDark
    }
}

.page-numbers.current,
a.page-numbers:hover {
    background: #f9f9f9;
}

.single-addition-content {
    word-break: break-word;
}

body.admin-bar {
    margin-top: 32px;
}

.text-indent {
    &-1 {
        text-indent: 1em;
    }
    &-2 {
        text-indent: 1.5em;
    }
    &-3 {
        text-indent: 2em;
    }
}

.responsive-video {
    position: relative;
    padding-bottom: 36.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.form_fields {
    width: 100%;
}

.form_content {
    padding: 50px;
    background: #eeeeee;
    @include b(tablet) {
        padding: 15px 20px;
        margin-top: 15px;
    }
    @include b(sm) {
        padding: 15px 20px
    }
    input, button {
        width: 100%;
    }
}

.form_wrapper {
    .responsive-video {
        @include b(xs) {
            padding-bottom: 40%;
        }
    }
    & > .d-flex {
        .responsive-video iframe{
            width: calc(100% - 15px);
        }
        @include b(desktop) {
            &.flex-row-reverse {
                .responsive-video iframe {
                    left: auto;
                    right: 0;
                }
            }
            &:not(.flex-row-reverse) {
                .responsive-video iframe {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    .responsive-video iframe {
        @include b(tablet) {
            width: 100% !important;
        }
    }
}

.form_inner {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

